import { GET_All_ONLINE_TEST, GET_All_TEST_SCORE, GET_SINGLE_ONLINE_TEST, SHOW_SINGLE_ONLINE_TEST_ANSWER } from "./actionType";

const initialState = {
    onlineTest: [],
    singleTest: [],
    testScore: [],
    singleTestAnswer: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_All_ONLINE_TEST:
            return { ...state, onlineTest: action.payload };
        case GET_SINGLE_ONLINE_TEST:
            return { ...state, singleTest: action.payload };
        case GET_All_TEST_SCORE:
            return { ...state, testScore: action.payload };
        case SHOW_SINGLE_ONLINE_TEST_ANSWER:
            return { ...state, singleTestAnswer: action.payload };
        default:
            return state;
    }
}
