import React, { Component } from "react";
import { connect } from "react-redux";

export class Questions extends Component {
    state = {
        selected: '',
    }
    selectedOption = (id,value,currect) => {

        this.setState({selected: value});

        this.props.callbackFromParent(value,id,currect)

    }
    render() {
        const {test} = this.props;
        return (
            <React.Fragment>
                <div className="question-card p-2 my-3">
                    <div className="single-test-heading py-2 px-3">
                        <h6
                            style={{
                                color: "#5762AB",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Question:
                        </h6>
                        <span
                            style={{
                                color: "#fff",
                                fontSize: "14px",
                                fontWeight: "400",
                            }}
                        >
                            {test.question}
                        </span>
                    </div>

                    <div className="mt-2">
                        <span
                            className="pb-2"
                            style={{
                                color: "#2B2C2F",
                                fontSize: "14px",
                                fontWeight: "500",
                            }}
                        >
                            Options:
                        </span>
                        <div
                            className={
                                test.option1 == this.state.selected
                                    ? "w-100 question-option my-2 question-option-active"
                                    : "w-100 question-option my-2"
                            }
                            onClick={() =>
                                this.selectedOption(
                                    test.id,
                                    test.option1,
                                    test.currect

                                )
                            }
                        >
                            {test.option1}
                        </div>
                        <div
                            className={
                                test.option2 == this.state.selected
                                    ? "w-100 question-option my-2 question-option-active"
                                    : "w-100 question-option my-2"
                            }
                            onClick={() =>
                                this.selectedOption(
                                    test.id,
                                    test.option2,
                                    test.currect
                                )
                            }
                        >
                            {test.option2}
                        </div>
                        <div
                            className={
                                test.option3 == this.state.selected
                                    ? "w-100 question-option my-2 question-option-active"
                                    : "w-100 question-option my-2"
                            }
                            onClick={() =>
                                this.selectedOption(
                                    test.id,
                                    test.option3,
                                    test.currect
                                )
                            }
                        >
                            {test.option3}
                        </div>
                        <div
                            className={
                                test.option4 == this.state.selected
                                    ? "w-100 question-option my-2 question-option-active"
                                    : "w-100 question-option my-2"
                            }
                            onClick={() =>
                                this.selectedOption(
                                    test.id,
                                    test.option4,
                                    test.currect
                                )
                            }
                        >
                            {test.option4}
                        </div>
                    </div>
                </div>

            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Questions);
