
import {
    GET_PARENT_PROFILE,
    PARENT_SIGNUP,
    GET_EDIT_PARENT,
    GET_STUDENT,
    GET_ALL_REMARKS,
    CHANGE_PASSWORD,
    LOGOUT_USER,
    GET_ALLERGIES,
    GET_MEDICAL_INSTRUCTION
} from "./actionType";

const initialState = {
    parent: [],
    student: [],
    remarks: [],
    allergies: [],
    medical: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case PARENT_SIGNUP:
            return { ...state, parent: action.payload };
        case GET_PARENT_PROFILE:
            return { ...state, parent: action.payload };
        case GET_STUDENT:
            return { ...state, student: action.payload };
        case GET_ALL_REMARKS:
            return { ...state, remarks: action.payload };
        case GET_ALLERGIES:
            return { ...state, allergies: action.payload };
        case GET_MEDICAL_INSTRUCTION:
            return { ...state, medical: action.payload };
        case LOGOUT_USER:
            return { ...state, parent: action.payload };
        default:
            return state;
    }
}
