import {
    GET_EDIT_PARENT,
    GET_PARENT_PROFILE,
    PARENT_SIGNUP,
    // GET_STUDENT_DETAILS,
    GET_STUDENT,
    GET_ALL_REMARKS,
    CHANGE_PASSWORD,
    LOGOUT_USER,
    PARENT_HELP,
    APPLY_STUDENT_LEAVE_REQUEST,
    GET_ALLERGIES,
    GET_MEDICAL_INSTRUCTION
} from "./actionType";


import {
    GET_PARENT_PROFILE_URL,
    GET_EDIT_PARENT_URL,
    PARENT_SIGNUP_URL,
    // GET_STUDENT_DETAILS_URL,
    GET_STUDENT_URL,
    GET_STUDENT_ATTENDANCE_URL,
    GET_ALL_REMARKS_URL,
    CHANGE_PASSWORD_URL,
    PARENT_HELP_URL,
    APPLY_STUDENT_LEAVE_REQUEST_URL,
    SUBMIT_STUDENT_ALLERGIES_URL,
    GET_STUDENT_ALLERGIES_URL,
    SUBMIT_MEDICAL_INSTRUCTION_URL,
    GET_MEDICAL_INSTRUCTION_URL,
    SUBMIT_STUDENT_FEEDBACK_URL
} from "../../../api";

import Axios from "axios";

export const getParentProfile = (token) => (dispatch) => {
    return Axios.post(GET_PARENT_PROFILE_URL, { token })
        .then((response) => {
            let parent = response.data;
            return dispatch({
                type: GET_PARENT_PROFILE,
                payload: parent
            })
        })
}


export const parentSignUp = (username, password) => (dispatch) => {
    return Axios.post(PARENT_SIGNUP_URL, {
        username,
        password,
    })
        .then((response) => {
            const parent = response.data;
            return dispatch({ type: PARENT_SIGNUP, payload: parent });
        })
        .catch(function (error) {
            console.log(error);
        });
};

export const editParentProfile = (formdata) => (dispatch) => {
    return Axios.post(GET_EDIT_PARENT_URL, formdata, {

    })
}
// export const getStudentDetails = (token,student_id) => (dispatch) => {
//     return Axios.post(GET_STUDENT_DETAILS_URL, { token,student_id }).then((response) => {
//         let student = response.data;
//         return dispatch({ type: GET_STUDENT_DETAILS, payload: student });
//     });
// };
//attendance
export const getStudentAttendance = (token, student_id) => (dispatch) => {
    return Axios.post(GET_STUDENT_ATTENDANCE_URL, { token, student_id })
    // .then((response) => {
    //     let attendance = response.data;
    //     return dispatch({
    //         type:GET_STUDENT_ATTENDANCE,
    //         payload:attendance
    //     })
    // })
}
export const getStudent = (token, student_id) => (dispatch) => {
    return Axios.post(GET_STUDENT_URL, { token, student_id })
        .then((response) => {
            let student = response.data;
            return dispatch({ type: GET_STUDENT, payload: student })
        })
};

export const logoutUser = () => (dispatch) => {

    return [
        dispatch({
            type: LOGOUT_USER,
            payload: [],
        }),
    ];
};



// remarks
export const getAllRemarks = (token, student_id) => (dispatch) => {
    return Axios.post(GET_ALL_REMARKS_URL, { token, student_id })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: GET_ALL_REMARKS,
                payload: student
            })
        })
}

// password
export const changePassword = (token, password, new_password) => (dispatch) => {
    return Axios.post(CHANGE_PASSWORD_URL, {
        token, password, new_password
    })
};

// help
export const sentHelp = (token, help_text) => (dispatch) => {
    return Axios.post(PARENT_HELP_URL, {
        token, help_text
    })
};

// allergies
export const sentAllergies = (token, id,instruction) => (dispatch) => {
    return Axios.post(SUBMIT_STUDENT_ALLERGIES_URL, {
        token, id,instruction
    })
};


export const getAllAllergies = (token, student_id) => (dispatch) => {
    return Axios.post(GET_STUDENT_ALLERGIES_URL, { token, student_id })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: GET_ALLERGIES,
                payload: student
            })
        })
}

//medical instruction
export const sentMedicalInstruction = (formData) => (dispatch) => {
    return Axios.post(SUBMIT_MEDICAL_INSTRUCTION_URL, formData)
};


export const getAllMedical = (token, student_id) => (dispatch) => {
    return Axios.post(GET_MEDICAL_INSTRUCTION_URL, { token, student_id })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: GET_MEDICAL_INSTRUCTION,
                payload: student
            })
        })
}


export const applyLeaveRequest = (token, student_id, from_date, to_date, reason) => (dispatch) => {
    return Axios.post(APPLY_STUDENT_LEAVE_REQUEST_URL, { token, student_id, from_date, to_date, reason })
        .then((response) => {
            let student = response.data;
            return dispatch({
                type: APPLY_STUDENT_LEAVE_REQUEST,
                payload: student
            })
        })
}


export const sentFeedback = (formData) => (dispatch) => {
    return Axios.post(SUBMIT_STUDENT_FEEDBACK_URL, formData)
};
