import React, { Component } from "react";
import { connect } from "react-redux";
import { BsFillGridFill } from "react-icons/bs";
import { RiMessage3Fill } from "react-icons/ri";
import { RiHome2Line } from "react-icons/ri";
import { FaShoppingBag, FaUserGraduate } from "react-icons/fa";
import { TiUser } from "react-icons/ti";
import { Link } from "react-router-dom";
import Ink from "react-ink";

class ParentProfile extends Component {
    state = {
        wall: false,
        home: false,
        message: false,
        store: false,
        profile: false,
    };
    componentDidMount() {
        const { current } = this.props;
        if (current === "home") {
            this.setState({ home: true });
        } else if (current === "wall") {
            this.setState({ wall: true });
        } else if (current === "message") {
            this.setState({ message: true });
        } else if (current === "store") {
            this.setState({ store: true });
        } else if (current === "profile") {
            this.setState({ profile: true });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="bottom-bar">
                    <div className="d-flex justify-content-evenly pt-3">
                        <Link
                            to="/events"
                            className="position-relative b-r-10 px-2 footer-link"
                        >
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100">
                                <BsFillGridFill
                                    className={
                                        this.state.wall
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {this.state.wall && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Events
                                    </span>
                                )}
                            </div>
                        </Link>
                        <Link
                            to="/my-chat"
                            className="position-relative b-r-10 px-2 footer-link"
                        >
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100">
                                <RiMessage3Fill
                                    className={
                                        this.state.message
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {this.state.message && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                    Chat
                                    </span>
                                )}
                            </div>
                        </Link>
                        <Link
                            to={"/home"}
                            className="position-relative b-r-10 px-2 footer-link"
                        >
                            <div className="home-icon">
                                <RiHome2Line className=" text-white" />
                            </div>
                        </Link>
                        <Link
                            to={"/store"}
                            className="position-relative b-r-10 px-2 footer-link"
                        >
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100">
                                <FaShoppingBag
                                    className={
                                        this.state.store
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {this.state.store && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Store
                                    </span>
                                )}
                            </div>
                        </Link>
                        <Link
                            to={"/parent-profile"}
                            className="position-relative b-r-10 px-2 footer-link"
                        >
                            <Ink className="touch-ink" />
                            <div className="d-flex flex-column align-items-center justify-content-around h-100">
                                <TiUser
                                    className={
                                        this.state.profile
                                            ? "footer-active"
                                            : "footer-inactive"
                                    }
                                />
                                {this.state.profile && (
                                    <span className={"keke-color fw-bold fs-7"}>
                                        Profile
                                    </span>
                                )}
                            </div>
                        </Link>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ParentProfile);
