import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { showTestAnswer } from "../../../../redux/Parent/onlineTest/action";
export class TestAnswer extends Component {
    state = {
        examId: this.props.match.params.id,
    };
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.showTestAnswer(
            user.data.auth_token,
            student_id.value,
            this.state.examId
        );
    }
    render() {
        const { singleTestAnswer } = this.props;
        return (
            <React.Fragment>
                <div className="mt-3 ">
                    <BackButton title={"Your Answer"} />
                </div>
                <div className="px-3 mt-5">
                    {singleTestAnswer?.data?.map((item) => (
                        <div className="question-card p-2 mt-3">
                            <div className="single-test-heading py-2 px-3">
                                <h6
                                    style={{
                                        color: "#5762AB",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Question:
                                </h6>
                                <span
                                    style={{
                                        color: "#fff",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                    }}
                                >
                                    {item?.question?.question}
                                </span>
                            </div>
                            <div className="mt-2">
                                <span
                                    className="pb-2"
                                    style={{
                                        color: "#2B2C2F",
                                        fontSize: "14px",
                                        fontWeight: "500",
                                    }}
                                >
                                    Options:
                                </span>
                                <br/>
                                <span
                                    className="pb-2"
                                    style={{
                                        color: "#2B2C2F",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                    }}
                                >
                                   Selected Answer: {item?.selected_answer}
                                </span>
                                <div
                                    className="w-100 question-option my-2"
                                    style={
                                        item?.question?.option1 == item?.correct_answer

                                            ? { color: "#7BBF45" }
                                            : { color: "#BF4474" }
                                    }
                                >
                                    {item?.question?.option1}
                                </div>
                                <div
                                    className="w-100 question-option my-2"
                                    style={
                                        item?.question?.option2 == item?.correct_answer

                                            ? { color: "#7BBF45" }
                                            : { color: "#BF4474" }
                                    }
                                >
                                    {item?.question?.option2}
                                </div>
                                <div
                                    className="w-100 question-option my-2"
                                    style={
                                        item?.question?.option3 == item?.correct_answer

                                            ? { color: "#7BBF45" }
                                            : { color: "#BF4474" }
                                    }
                                >
                                    {item?.question?.option3}
                                </div>
                                <div
                                    className="w-100 question-option my-2"
                                    style={
                                        item?.question?.option4 == item?.correct_answer

                                            ? { color: "#7BBF45" }
                                            : { color: "#BF4474" }
                                    }
                                >
                                    {item?.question?.option4}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    singleTestAnswer: state.onlineTest.singleTestAnswer,
});

export default connect(mapStateToProps, { showTestAnswer })(TestAnswer);
