import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Ink from 'react-ink';
import { withRouter } from 'react-router-dom';

class BackButton extends Component {

    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        const { title } = this.props;
        // console.log(title);
        return (
            <React.Fragment>
                <div className='pt-4 '>
                    <div className='d-flex flex-row align-items-center justify-content-between position-relative ms-2'>
                        <div className='p-2 fw-bold' style={{ borderRadius: '100px' }} onClick={() => this.onHandleBack()}>
                            <span className='fw-bold'>
                                <HiOutlineChevronLeft size="1.5em" color={this.props.color?this.props.color:"#C24A6B"} />
                            </span>
                        </div>
                        <div className='subject-head d-flex justify-content-center'>
                            <span className='' style={{
                                color:this.props.color?this.props.color:"#C24A6B"
                            }}>{title}</span>
                        </div>
                        <div className='pe-5'>
                            <span>

                            </span>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({})


export default withRouter(connect(mapStateToProps, {})(BackButton))
