import React, { Component } from 'react'
import { connect } from 'react-redux'
import background from '../../../assets/Images/hw.png'
import qstn1 from '../../../assets/Images/qstn1.png'
import qstn2 from '../../../assets/Images/qstn2.png'
import qstn3 from '../../../assets/Images/qstn3.png'
import { DatePicker } from 'antd';
import ContentLoader from 'react-content-loader'
import BackButton from '../../../elements/BackButton'
import Form from 'react-bootstrap/Form';
import { getSingleHomeWork } from '../../../redux/Parent/class/action'
import { IMAGE_BASE_URL } from '../../../api'
import Ink from 'react-ink'
import { Link } from 'react-router-dom'



class SingleHomework extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props.getSingleHomeWork(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, });
            }
        })
    }

    downloadHomeWork = () => {
        const { user } = this.props;

    }
    render() {
        const { homework } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (

                        <React.Fragment>
                            <ContentLoader viewBox="0 0 280 550" backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="13" y="66" rx="10" ry="10" width="255" height="130" />
                                <rect x="13" y="218" rx="10" ry="10" width="255" height="50" />
                                <rect x="13" y="300" rx="10" ry="10" width="255" height="75" />
                                <rect x="180" y="395" rx="10" ry="10" width="90" height="30" />
                                <rect x="13" y="480" rx="10" ry="10" width="255" height="50" />
                            </ContentLoader>

                        </React.Fragment>
                    ) : (

                        <React.Fragment>
                            <div>
                                <BackButton title={"Home Work"} />
                            </div>
                            <div className="px-4 mt-5">
                                <div>
                                    <div style={{ background: 'rgba(190, 175, 179, 0.05)', borderRadius: '10px', padding: '6px' }}>
                                        <div className='single-hw-img' style={{ backgroundImage: `url(${background})` }}>
                                        </div>
                                    </div>

                                    {/* <div className='mt-4'>
                                        <DatePicker className='subject-date' />
                                    </div> */}

                                    <div>
                                        <div className="d-flex justify-content-between mt-4">
                                            <span className="single-hw-text" >Questions</span>
                                            <span className="single-hw-text">Submit Date : {homework?.homework?.submit_date}</span>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <span className="single-hw-text2" >
                                                Subject : {homework?.homework?.subject?.name}</span>
                                            <span className="single-hw-text2">
                                                class {homework?.homework?.section?.student_class?.name}  Section {homework?.homework?.section?.name}</span>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center mt-3" >
                                            <div>
                                                download homework
                                            </div>
                                            <a href={IMAGE_BASE_URL + homework?.homework?.assignment} target="_blank" download className="btn subject-download position-relative" >
                                                Download
                                                <Ink style={{ color: '#aaaaaa' }} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-grid gap-2 px-4 position-fixed bottom-10  w-100">
                                <Link to="/upload-homework" >
                                    <div className="login-button text-center py-3" style={{ fontSize: '14px' }}>
                                        Upload Your Homework
                                    </div>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    homework: state.class.homework
})


export default connect(mapStateToProps, {
    getSingleHomeWork,
})(SingleHomework)
