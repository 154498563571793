import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import Form from "react-bootstrap/Form";
import { DatePicker, } from "antd";
import moment from "moment";
import Modal from "react-modal";
import { sentMedicalInstruction ,getAllMedical} from '../../../redux/Parent/user/action';
import { CgCheckO } from 'react-icons/cg';

export class MedicalInstruction extends Component {

    state = {
        instruction: "",
        toDate: "",
        fromDate: "",
        open: false,
    }

    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAllMedical(user.data.auth_token, student_id.value);
    }

    handleInput = (e) => {
        this.setState({ instruction: e.target.value });
    }
    onChangeFromDate = (date, dateString) => {

        this.setState({ fromDate: dateString });

    }
    onChangeToDate = (date, dateString) => {

        this.setState({ toDate: dateString });

    }
    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = () => {
        this.setState({ open: false });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        let formdata = new FormData();
        formdata.append("student_id", student_id.value);
        formdata.append("instruction", this.state.instruction);
        formdata.append("toDate", this.state.toDate);
        formdata.append("fromDate", this.state.fromDate);
        formdata.append('token', user.data.auth_token);


        this.props.sentMedicalInstruction(formdata).then((response) => {

            if (response.data.success) {
                this.modalOpen();
                e.target.reset();
                this.setState({
                    toDate: "", fromDate: ""
                })
                this.props.getAllMedical(user.data.auth_token, student_id.value);
            }
        })
    }
    render() {
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        const disabledDate = (current) => {
            // Can not select days before today
            return current && current <= moment().startOf("day");
        };
        const {medical} = this.props;
        return (
            <React.Fragment>

                <BackButton title={"Medical Instruction"} color="#C24A6B" />

                <div className="position-relative mt-5 pb-5">
                    <Form onSubmit={this.onSubmit}>
                        <div className="position-relative px-2">
                            <div className=" flex-column p-1">
                                <div className="d-flex justify-content-between mt-3 homework-input">
                                    <div className="text-muted"> Student </div>
                                    <div style={{ fontWeight: "600" }}>
                                        {student_id?.label}
                                    </div>
                                </div>
                            </div>

                            <div className=" flex-column p-1">
                                <div className="  pt-2 ">
                                    <Form.Group className=" ">
                                        <textarea
                                            className="appointment-textarea pt-2 px-2"
                                            rows={3}
                                            placeholder="Instruction"
                                            required
                                            onChange={(e) => {
                                                this.handleInput(e)
                                            }}
                                        ></textarea>
                                    </Form.Group>
                                </div>
                            </div>
                            <div className="pt-2">
                                <DatePicker
                                    className="appointment-date"
                                    placeholder="From Date"
                                    disabledDate={disabledDate}
                                    onChange={this.onChangeFromDate}
                                    required
                                />
                            </div>
                            <div className="pt-2">
                                <DatePicker
                                    className="appointment-date"
                                    placeholder="To Date"
                                    disabledDate={disabledDate}
                                    onChange={this.onChangeToDate}
                                    required
                                />
                            </div>
                        </div>
                        <div
                            className="d-flex mb-3"
                            style={{ margin: "0 18px" }}
                        >
                            <button type="submit" className="appointment-btn">
                                Submit
                            </button>
                        </div>
                    </Form>
                </div>
                <React.Fragment>
                    <div className="d-flex flex-column align-content-around justify-content-around px-3 pb-7">
                        {medical?.data?.map((data) => (
                            <div
                                className="appointment-card py-2 px-2 b-r-10 mt-4"
                            >
                                <div className="d-flex flex-row justify-content-around align-items-center ">

                                    <div className="flex-grow-1 ps-2">
                                        <div className="d-flex flex-column h-100 justify-content-between align-content-between">

                                            <span className="  fs-8 fw-500">
                                                {student_id.label}
                                            </span>
                                            <span className="  fs-8 fw-500">
                                                From Date : {data?.from_date}
                                            </span>
                                            <span className="  fs-8 fw-500">
                                                To Date : {data?.to_date}
                                            </span>
                                            <span className=" fs-10 ">
                                                Instruction:
                                                {data?.instructions}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </React.Fragment>
                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={() => this.modalClose()}
                    style={modalStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "70vw",
                            width: '70vw',
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-center align-items-between">
                                <div className='d-flex justify-content-center mt-5'
                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                    <CgCheckO color={"#7ECB40"} size={"4em"} />
                                </div>
                                <div className='d-flex justify-content-center mt-4'
                                    style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                    Medical Instruction Added Successfully</div>
                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({

    user: state.user.parent,
    medical: state.user.medical
})


export default connect(mapStateToProps, { sentMedicalInstruction ,getAllMedical})(MedicalInstruction)
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
