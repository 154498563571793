import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import { getStudentAllergies } from '../../../redux/Teacher/student/action';
import img from '../../../assets/Images/noreview.png'
import ContentLoader from 'react-content-loader';
export class StudentAllergies extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        this.setState({
            loading: true,
        })
        const { user } = this.props;
        this.props.getStudentAllergies(user.data.auth_token);
        setTimeout(() => {
            this.setState({ loading: false })
        }, 1500)
    }
    render() {
        const { allergies } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                            <rect x="10" y="30" rx="5" ry="5" width="25" height="25" />
                            <rect x="100" y="30" rx="10" ry="10" width="80" height="25" />
                            <rect x="10" y="70" rx="10" ry="10" width="260" height="120" />
                            <rect x="10" y="220" rx="10" ry="10" width="260" height="120" />
                            <rect x="10" y="370" rx="10" ry="10" width="260" height="120" />


                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <>
                            <BackButton title={"Student Allergies"} />
                            <div className='px-3 mt-4x pb-4'>
                                {allergies?.data?.length > 0 ?
                                    <>
                                        {allergies?.data?.length > 0 && allergies?.data?.map((data) => (
                                            <div className="mt-3 review-container px-2 pb-4 pt-2" style={{ fontSize: '12px', fontWeight: '500' }}>
                                                <div className='review-head d-flex justify-content-between px-3 py-2 text-white'>
                                                    <div className='d-flex flex-column'>
                                                        <span>{data?.student?.user?.name} </span>
                                                        <span>Class {data?.class?.name} Section {data?.section?.name}</span>
                                                    </div>

                                                </div>
                                                <div className='text-muted px-1 pb-1 pt-2' style={{ fontSize: '13px' }}><span>{data?.instructions}</span></div>
                                            </div>
                                        ))}
                                    </>
                                    :
                                    <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                                        <img src={img} alt="no allergies" style={{ width: '65vw' }} className='' />
                                        <span>No Allergies Found...</span>
                                    </div>
                                }

                            </div>

                        </>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    allergies: state.student.allergies

})



export default connect(mapStateToProps, { getStudentAllergies })(StudentAllergies)
