import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { submitTestQuestions } from "../../../../../../redux/Teacher/onlineTest/action";
export class EditSingleQuestion extends Component {
    state={

        answer: {},
        error: false,
        editQuestion: {},

    }

    componentDidMount(){
        this.setState({
            editQuestion: this.props.question,
        })

    }

    handleInput = (e,type) => {
        const question = this.state.editQuestion;

        question[type] = e.target.value;
        this.setState({
            editQuestion: question,

        })

    }

    handleAnswer = (answer) => {
        this.setState({answer});
    }

    onSubmit = (e) => {
        const {user} = this.props;
        e.preventDefault();
        let formData = new FormData();
        // if (this.state.answer.value === undefined) {
        //     this.setState({
        //         error: true,
        //     })
        // }
        formData.append('question',this.state.editQuestion?.question);
        formData.append('option1',this.state.editQuestion?.option1);
        formData.append('option2',this.state.editQuestion?.option2);
        formData.append('option3',this.state.editQuestion?.option3);
        formData.append('option4',this.state.editQuestion?.option4);
        formData.append('answer',this.state.answer.value);
        formData.append('token',user.data.auth_token);
        formData.append('exam_id',this.props.examId);
        formData.append('qus_id',this.state.editQuestion?.id);

        this.props.submitTestQuestions(formData).then((response) => (
            console.log(response)
        ))
    }

    render() {

        const {editQuestion, answer} = this.state;
        const options = [
            { value: editQuestion?.option1, label: editQuestion?.option1 },
            { value: editQuestion?.option2, label: editQuestion?.option2 },
            { value: editQuestion?.option3, label: editQuestion?.option3 },
            { value: editQuestion?.option4, label: editQuestion?.option4 }
        ]

        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit}>
                    <div className="question-card p-2 my-3">
                        <div className="single-test-heading py-2 px-3">
                            <h6
                                style={{
                                    color: "#5762AB",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Question:
                            </h6>
                            <input
                                style={{
                                    color: "#fff",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    background: "#a6afea",
                                }}
                                className="single-question-input "
                                type="text"
                                required
                                value={this.state?.editQuestion?.question}
                                placeholder="Type Question Here..."
                                name="question"
                                onChange={(e)=>{
                                    this.handleInput(e,'question');
                                }}
                            ></input>
                        </div>

                        <div className="mt-2">
                            <span
                                className="pb-2"
                                style={{
                                    color: "#2B2C2F",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Options:
                            </span>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                value={this.state?.editQuestion?.option1}
                                placeholder="Option 1"
                                name="option1"
                                onChange={(e)=>{
                                    this.handleInput(e,'option1');
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 2"
                                value={this.state?.editQuestion?.option2}
                                name="option2"
                                onChange={(e)=>{
                                    this.handleInput(e,'option2');
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 3"
                                name="option3"
                                value={this.state?.editQuestion?.option3}
                                onChange={(e)=>{
                                    this.handleInput(e,'option3');
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 4"
                                value={this.state?.editQuestion?.option4}
                                name="option4"
                                onChange={(e)=>{
                                    this.handleInput(e,'option4');
                                }}
                            ></input>
                        </div>
                        <div className="mt-2">
                            <span
                                className="pb-2"
                                style={{
                                    color: "#2B2C2F",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Answer:
                            </span>

                            <Select
                            className="my-2 single-question-input"

                            required
                            value={answer?.value ? answer : options.filter((option) => option?.value === editQuestion?.currect)}
                            onChange={this.handleAnswer}
                            options={options}
                        />
                        </div>
                        <div className="d-flex justify-content-end  me-2">
                            <button type="submit" className="btn btn-danger py-1 px-2">
                                Submit
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
});



export default connect(mapStateToProps, {submitTestQuestions})(EditSingleQuestion);

