import React, { Component } from "react";
import { Form, } from "react-bootstrap";
import { connect } from "react-redux";
import Modal from "react-modal";
import BackButton from "../../../../elements/BackButton";
import {
    getSingleOnlineTest,
    submitAllAnswer,
} from "../../../../redux/Parent/onlineTest/action";
import Questions from "./Questions";
import Lottie from "react-lottie";
import animationData from "../../../../assets/lottie/lf30_editor_bspltctv.json";
import { Link } from "react-router-dom";
export class SingleTest extends Component {
    state = {
        examId: this.props.match.params.id,
        allAnswer: [],
        open: false,
    };

    componentDidMount() {
        const { user } = this.props;
        this.props.getSingleOnlineTest(user.data.auth_token, this.state.examId);
    }

    getAnswer = (selected_option, id,currect) => {
        if (this.state.allAnswer.length > 0) {
            this.state.allAnswer.forEach((item, index) => {
                if (item.qusID == id && item.answer != selected_option) {
                    this.state.allAnswer.splice(index, 1);
                } else {
                    this.state.allAnswer.push({
                        qusID: id,
                        answer: selected_option,
                        correct: currect
                    });
                }
            });
        } else {
            this.state.allAnswer.push({ qusID: id, answer: selected_option , correct: currect});
        }
    };

    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.submitAllAnswer(
            user?.data?.auth_token,
            student_id?.value,
            this.state?.examId,
            this.state?.allAnswer
        ).then((response)=>{

            if(response.data.success){
                this.setState({open: true});

            }
            else{
                console.log("Something went wrong")
            }
        })
    };

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        const { singleTest } = this.props;
        return (
            <React.Fragment>
                <div className="mt-3 px-3">
                    <BackButton title={"Online Test"} />
                </div>
                <div className="px-3 mt-5">
                    <span
                        style={{
                            color: "#2B2C2F",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}
                    >
                        No.Questions : {singleTest?.data?.length}
                    </span>
                    <Form>
                        {singleTest?.data?.map((test, index) => (
                            <Questions
                                test={test}
                                callbackFromParent={this.getAnswer}
                            />
                        ))}

                        <div className="pb-3 w-100">
                            <button
                                type="Submit"
                                className="attendence-btn w-100"
                                onClick={(e) => this.onSubmit(e)}
                            >
                                Submit
                            </button>
                        </div>

                    </Form>

                </div>
                <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={modelCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "180px", zIndex: '999999999999999999999' }}
                    >

                        <div className="px-10 col-12 d-flex justify-content-between align-items-center text-center">
                            <span className="mt-2 mb-0" style={{ fontSize: '1em', fontWeight: '700' }}>
                                Succesfully Completed
                            </span>
                        </div>

                        <div className="">
                            <Lottie
                                 options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        </div>
                        <Link to={'/test-score'}>
                        <div className="d-flex justify-content-center mt-2">
                            <div className="text-center w-100">
                                <button className="go-back-button p-2 px-3 text-center text-white position-relative" style={{background:"#7bbf45 "}}>
                                    View Score

                                </button>
                            </div>
                        </div>
                        </Link>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    singleTest: state.onlineTest.singleTest,
});

export default connect(mapStateToProps, {
    getSingleOnlineTest,
    submitAllAnswer,
})(SingleTest);

const modelCustomStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #7bbf45 ',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
