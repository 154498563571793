import React, { Component } from "react";
import { Form } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { connect } from "react-redux";
import BackButton from "../../../elements/BackButton";
import Select from "react-select";
import { TbFilePlus } from "react-icons/tb";
import Truncate from "react-truncate";
import Modal from "react-modal";
import moment from "moment";
import Ink from "react-ink";
import { Link } from "react-router-dom";
import animationData from "../../../assets/lottie/lf30_editor_bspltctv.json";
import Lottie from "react-lottie";
import {
    getTeacherSchoolData,
    addHomeWork,
} from "../../../redux/Teacher/user/action";
import { CgCheckO } from "react-icons/cg";

export class TeacherHomeWork extends Component {
    state = {
        loading: false,
        sections: [],
        subjects: [],
        students: [],
        homework: {
            title: "",
            date: "",
            file: {},
        },
        filename: "",
        open: false,
        class_id: '',
    };
    componentDidMount() {
        const { teacher } = this.props;
        this.props
            .getTeacherSchoolData(teacher.data.auth_token)
            .then((response) => {
                this.setState({ EditUser: response.payload.data });
            });
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
        console.log(this.state.file);
    }

    uploadHomeWork = (e) => {
        e.preventDefault();
        const { teacher } = this.props;
        const { homework } = this.state;
        let formData = new FormData();
        formData.append("section_id", homework.section_id);
        formData.append("subject_id", homework.subject_id);
        formData.append("submit_date", homework.submit_date);
        formData.append("class_id", this.state.class_id);
        formData.append("file", homework.file);
        formData.append("token", teacher.data.auth_token);
        this.props.addHomeWork(formData).then((response) => {
            console.log(response)
            if (response && response.data && response.data.success) {
                this.setState({ open: true, homework: null });
            }
        });
    };

    getSections = (val) => { };

    getSubjects = (val) => {
        const { schooldata } = this.props;
        this.setState({ class_id: val })
        const section_subjects = schooldata?.data?.student_classes
            ?.find((data) => data.id == val)
            ?.subjects?.map((subject) => ({
                value: subject.id,
                label: subject?.name,
            }));

        this.setState({ subjects: section_subjects });

        const sections = schooldata?.data?.student_classes
            ?.find((data) => data.id == val)
            ?.sections?.map((section) => ({
                value: section.id,
                label: section.name,
            }));
        this.setState({ sections: sections });
    };

    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = (e) => {
        e.preventDefault();

        this.setState({ open: false });
    };

    handleInput(e, name) {
        let data = this.state.homework;
        data[name] = e;
        this.setState({ homework: data });
    }

    GoBack = (e) => {
        e.preventDefault();
        this.props.history.goBack();
    };

    today = () => {
        let date = moment().format("YYYY-MM-DD");
        console.log(date);
        return date;
    };

    render() {
        const { title, date, file } = this.state;
        console.log(this.state.subjects);
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        const { schooldata } = this.props;
        const { sections, subjects, students } = this.state;
        const classes = schooldata?.data?.student_classes?.map((data) => ({
            value: data.id,
            label: data.name,
        }));
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 280 550"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="13"
                                y="66"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                            <rect
                                x="13"
                                y="218"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                            <rect
                                x="13"
                                y="300"
                                rx="10"
                                ry="10"
                                width="255"
                                height="75"
                            />
                            <rect
                                x="180"
                                y="395"
                                rx="10"
                                ry="10"
                                width="90"
                                height="30"
                            />
                            <rect
                                x="13"
                                y="480"
                                rx="10"
                                ry="10"
                                width="255"
                                height="50"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Homework"} />
                        <Form onSubmit={this.uploadHomeWork}>
                            <div className="position-relative mt-4 px-3">
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Class"
                                        options={classes}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(value, "class_id");
                                            this.getSubjects(value);
                                        }}
                                    />
                                </div>
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Section"
                                        options={sections}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(
                                                value,
                                                "section_id"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="teacher-homework-input mt-3 ">
                                    <Select
                                        className="text-muted "
                                        styles={customStyles}
                                        placeholder="Subject"
                                        options={subjects}
                                        onChange={(e) => {
                                            let value = e.value;
                                            this.handleInput(
                                                value,
                                                "subject_id"
                                            );
                                        }}
                                    />
                                </div>
                                <div className="mt-3">
                                    <label className="text-muted px-2">Date</label>
                                    <input
                                        type="date"
                                        className="input-homework w-100"
                                        placeholder="Date"
                                        required
                                        onChange={(e) => {
                                            let date = e.target.value;
                                            console.log(date);
                                            this.handleInput(date, "submit_date");
                                        }}
                                    />
                                </div>


                                <div className="d-flex align-items-center justify-content-between mt-3 homework-input">
                                    <div className="flex-grow-1">
                                        <Truncate lines={1}>
                                            <span>
                                                {this.state.filename
                                                    ? this.state.filename
                                                    : "upload pdf file"}
                                            </span>
                                        </Truncate>
                                    </div>
                                    <label
                                        for="file"
                                        className="user-select-none px-2 image-input"
                                    >
                                        Upload files{" "}
                                        <TbFilePlus size={"1.4em"} />
                                    </label>
                                    <input
                                        type="file"
                                        accept=".pdf"
                                        style={{ display: "none" }}
                                        id="file"
                                        onChange={(e) => {
                                            let file = e.target.files[0];

                                            this.setState({ file: URL.createObjectURL(file) });
                                            this.handleInput(file, "file");
                                            this.setState({ filename: file.name });
                                        }}
                                        required
                                    />
                                </div>
                            </div>
                            <div className="d-grid gap-2 px-3 position-fixed bottom-10  w-100">
                                <button
                                    type="submit"
                                    className="appointment-btn py-3"
                                >
                                    <span>Upload</span>
                                </button>
                            </div>
                        </Form>
                    </React.Fragment>
                )}
                {/* <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={modelCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "180px",
                            zIndex: "999999999999999999999",
                        }}
                    >
                        <div className="px-10 col-12 d-flex justify-content-between align-items-center text-center">
                            <span
                                className="mt-2 mb-0"
                                style={{ fontSize: "1em", fontWeight: "700" }}
                            >
                                Succesfully Uploaded Homework
                            </span>
                        </div>

                        <div className="">
                            <Lottie
                                options={defaultOptions}
                                height={100}
                                width={100}
                            />
                        </div>
                        <div className="d-flex justify-content-center mt-2">
                            <div className="text-center w-100">
                                <button
                                    className="go-back-button p-2 px-3 text-center text-white position-relative"
                                    onClick={(e) => this.GoBack(e)}
                                >
                                    Go Back
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal> */}
                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={(e) => this.modalClose(e)}
                    style={modelCustomStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "45vw",
                            width: "70vw",
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-between align-items-center">
                                <div
                                    className="d-flex justify-content-center"
                                    style={{
                                        fontSize: "18px",
                                        fontWeight: "600",
                                        color: "#2B2C2F",
                                    }}
                                >
                                    <CgCheckO color={"#7ECB40"} size={50} />
                                </div>
                                <div
                                    className="d-flex justify-content-center text-center mt-1"
                                    style={{
                                        fontSize: "1.1em",
                                        fontWeight: "400",
                                        color: "#2B2C2F",
                                    }}
                                >
                                    Home Work Added Successfully
                                </div>

                                <button
                                    style={{
                                        color: "#fff",
                                    }}
                                    className="btn btn-sm btn-success px-3 mt-1"
                                    onClick={(e) => this.modalClose(e)}
                                >
                                    OK
                                </button>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
});
export default connect(mapStateToProps, { getTeacherSchoolData, addHomeWork })(
    TeacherHomeWork
);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "none",
        border: "none", // tried border: 'none'
        boxShadow: "none", // tried border: 'none'
        outline: "none",
    }),
    control: (provided) => ({
        ...provided,
        height: "45px",
        border: "none",
        boarderRadius: "15px",
        fontSize: "13px",
        borderStyle: "none",
        backgroundColor: "#F3F5F7",
        outline: "none",
    }),
};

const modelCustomStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #bf4672",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
