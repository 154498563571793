import React, { Component } from 'react'
import { RiDeleteBin7Line } from 'react-icons/ri'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import BackButton from '../../../elements/BackButton'
import img from "../../../assets/Images/exam.png";
import { getAllOnlineExam } from "../../../redux/Teacher/onlineTest/action"
import moment from "moment"
import { Button } from 'react-bootstrap'
import ContentLoader from 'react-content-loader'
export class TeacherAllOnlineTest extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props.getAllOnlineExam(user.data.auth_token);
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500)
    }
    render() {
        const { exams } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="-2 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                           <rect x="3" y="30" rx="10" ry="10" width="270" height="140" />
                           <rect x="3" y="180" rx="10" ry="10" width="270" height="140" />
                           <rect x="3" y="330" rx="10" ry="10" width="270" height="140" />
                           <rect x="3" y="520" rx="10" ry="10" width="270" height="50" />

                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <BackButton title={"Online Test"} />
                        {exams?.data?.length > 0 ? (
                            <div className='mt-3 px-3 ' style={{
                                paddingBottom: "15vh"
                            }}>
                                {exams?.data?.map((exam) => (
                                    <div >
                                        <div className='d-flex flex-column px-3 py-2 position-relative mt-3' style={{ backgroundColor: '#f4f4f4', borderRadius: '12px' }}>
                                            <div className='mt-1' style={{ color: '#b3b3b3' }}>Exam Name : <span className='fw-bolder text-muted'>{exam?.name}</span></div>
                                            <div className='mt-1' style={{ color: '#b3b3b3' }}>Subject : <span className='fw-bolder text-muted'>{exam?.subject?.name}</span></div>
                                            <div className='mt-1' style={{ color: '#b3b3b3' }}>Class : <span className='fw-bolder text-muted'>{exam?.class?.name}</span></div>
                                            <div className='mt-1' style={{ color: '#b3b3b3' }}>Term : <span className='fw-bolder text-muted' style={{ color: '#72AFBC' }}>{exam?.term?.name}</span></div>
                                            <div className='mt-1' style={{ color: '#b3b3b3' }}>Starts at : <span className='fw-bolder text-muted'>{moment(new Date("1970-01-02T" + exam.from_time).getTime()).format('LT')} - {moment(exam?.exam_date).format('YYYY-MM-DD')}</span></div>



                                            <div className="d-flex align-items-center justify-content-between mt-2">


                                                <Link to={"/teacher-single-test/" + exam.id}>
                                                    <button className=' p-1 px-2' style={{
                                                        all: "unset",
                                                        background: "#DBEAFF",
                                                        color: '#0D6EFD',
                                                        borderRadius: "5px",
                                                    }}>View Exam Questions</button>
                                                </Link>

                                                <Link to={"/student-test-answer/" + exam.id}>
                                                    <button className='text-white bg-primary p-1 px-2' style={{
                                                        all: "unset",
                                                        borderRadius: "5px",
                                                    }}>View Result</button>
                                                </Link>
                                            </div>
                                        </div>

                                    </div>

                                ))
                                }


                            </div>
                        ) : (<div
                            className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data px-3"
                            style={{

                                height: "50vh"
                            }}
                        >
                            <img
                                src={img}
                                alt="leave"
                                style={{
                                    width: "100%",
                                }}
                            ></img>
                            <div className=" fs-5 fw-500">
                                No Online Test
                            </div>
                        </div>)
                        }
                        <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                            <Link to="/create-online-test">
                                <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                                    Create Online Test
                                </button>
                            </Link>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    exams: state.onlineExam.allOnlineExam
})


export default connect(mapStateToProps, { getAllOnlineExam })(TeacherAllOnlineTest)
