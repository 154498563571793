import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import Select from "react-select";
import { submitTestQuestions } from "../../../../../../redux/Teacher/onlineTest/action";
export class SingleQuestion extends Component {
    state = {
        question: "",
        option1: "",
        option2: "",
        option3: "",
        option4: "",
        answer: {},
        error: false,

    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value,

        })

    }

    handleAnswer = (answer) => {
        this.setState({ answer });
    }

    onSubmit = (e) => {
        const { user } = this.props;
        e.preventDefault();
        let formData = new FormData();
        if (this.state.answer.value === undefined) {
            this.setState({
                error: true,
            })
        }
        formData.append('question', this.state.question);
        formData.append('option1', this.state.option1);
        formData.append('option2', this.state.option2);
        formData.append('option3', this.state.option3);
        formData.append('option4', this.state.option4);
        formData.append('answer', this.state.answer.value);
        formData.append('token', user.data.auth_token);
        formData.append('exam_id', this.props.examId);

        this.props.submitTestQuestions(formData).then((response) => {

            if (response.data.success) {
                this.setState({
                    question: "",
                    option1: "",
                    option2: "",
                    option3: "",
                    option4: "",
                    answer: {},
                    error: false,
                })
                e.target.reset();
            }
        }).catch((error) =>{
            console.log(error)
        })
    }

    render() {

        const options = [
            { value: this.state.option1, label: this.state.option1 },
            { value: this.state.option2, label: this.state.option2 },
            { value: this.state.option3, label: this.state.option3 },
            { value: this.state.option4, label: this.state.option4 }
        ]
        return (
            <React.Fragment>
                <Form onSubmit={this.onSubmit}>
                    <div className="question-card p-2 my-3">
                        <div className="single-test-heading py-2 px-3">
                            <h6
                                style={{
                                    color: "#5762AB",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Question:
                            </h6>
                            <input
                                style={{
                                    color: "#fff",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    background: "#a6afea",
                                }}
                                className="single-question-input "
                                type="text"
                                required
                                placeholder="Type Question Here..."
                                name="question"
                                onChange={(e) => {
                                    this.handleInput(e);
                                }}
                            ></input>
                        </div>

                        <div className="mt-2">
                            <span
                                className="pb-2"
                                style={{
                                    color: "#2B2C2F",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Options:
                            </span>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 1"
                                name="option1"
                                onChange={(e) => {
                                    this.handleInput(e);
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 2"
                                name="option2"
                                onChange={(e) => {
                                    this.handleInput(e);
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 3"
                                name="option3"
                                onChange={(e) => {
                                    this.handleInput(e);
                                }}
                            ></input>
                            <input
                                className={
                                    "w-100 question-option my-2 single-question-input"
                                }
                                type="text"
                                required
                                placeholder="Option 4"
                                name="option4"
                                onChange={(e) => {
                                    this.handleInput(e);
                                }}
                            ></input>
                        </div>
                        <div className="mt-2">
                            <span
                                className="pb-2"
                                style={{
                                    color: "#2B2C2F",
                                    fontSize: "14px",
                                    fontWeight: "500",
                                }}
                            >
                                Answer: {this.state.error ? <span className="text-danger">Select the answer</span> : null}
                            </span>

                            <Select
                                className="my-2 single-question-input"
                                placeholder="Answer"
                                required
                                onChange={this.handleAnswer}
                                options={options}
                            />
                        </div>
                        <div className="d-flex justify-content-end  me-2">
                            <button type="submit" className="btn btn-danger py-1 px-2">
                                Submit
                            </button>
                        </div>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
});



export default connect(mapStateToProps, { submitTestQuestions })(SingleQuestion);

