import Attendance from "../components/Parent/Attendence";

// export const WEBSITE_URL = "http://localhost:8000/api"; //for localhost route uses
// export const WEBSITE_BASE_URL = "http://localhost:8000"; //for localhost route uses
// export const IMAGE_BASE_URL = "http://localhost:8000/"; //for localhost route uses

export const WEBSITE_URL = "https://classreport.in/public/api";   //for live route uses
export const WEBSITE_BASE_URL = "https://classreport.in/public";   //for live route uses
export const IMAGE_BASE_URL = "https://classreport.in/public/";   //for live route uses

//----------------------------------Parent App Start-------------------------//

//home page
export const GET_ALL_HOME_PAGE_DATA_URL = WEBSITE_URL + "/get-all-home-page-data";

//profile page
export const GET_PARENT_PROFILE_URL = WEBSITE_URL + "/get-parent-profile";

//edit profile page
export const GET_EDIT_PARENT_URL = WEBSITE_URL + "/get-edit-parent";

// tutorial
export const GET_TUTORIALS_URL = WEBSITE_URL + "/get-tutorials";

//homework
export const GET_HOMEWORKS_URL = WEBSITE_URL + "/get-homeworks";
export const GET_SINGLE_HOME_WORKS_URL = WEBSITE_URL + "/get-single-homeworks";
export const GET_HOME_WORK_DETAILS_URL = WEBSITE_URL + "/get-homework-details";
export const UPLOAD_HOME_WORK_URL = WEBSITE_URL + "/upload-homework";

//subjects
export const GET_ALL_SUBJECTS_URL = WEBSITE_URL + "/get-all-subjects";
export const GET_SINGLE_SUBJECT_URL = WEBSITE_URL + "/get-single-subject";

// Teachers
export const GET_ALL_TEACHERS_URL = WEBSITE_URL + "/get-all-teachers";
export const GET_SINGLE_TEACHER_URL = WEBSITE_URL + "/get-single-teacher";

//fees
export const GET_FEES_DATA_URL = WEBSITE_URL + "/get-fees-data";
export const VIEW_ALL_FEES_URL = WEBSITE_URL + "/view-all-fees";

// parent sign up
export const PARENT_SIGNUP_URL = WEBSITE_URL + "/parent-signup";

//student details
export const GET_STUDENT_DETAILS_URL = WEBSITE_URL + "/get-student-details";
export const GET_STUDENT_URL = WEBSITE_URL + "/get-student";

//mark
export const VIEW_MARK_URL = WEBSITE_URL + "/view-mark";
export const GET_MARK_URL = WEBSITE_URL + "/get-mark";

//online test
export const GET_All_ONLINE_TEST_URL = WEBSITE_URL + "/get-all-online-test";
export const GET_SINGLE_ONLINE_TEST_URL = WEBSITE_URL + "/get-single-online-test";
export const SHOW_SINGLE_ONLINE_TEST_ANSWER_URL = WEBSITE_URL + "/show-single-test-answer";
export const SUBMIT_ALL_ANSWER_URL = WEBSITE_URL + "/submit-all-answer";
export const GET_ALL_TEST_SCORE_URL = WEBSITE_URL + "/get-test-score";

// appointments
export const GET_STUDENT_APPOINTMENT_DETAILS_URL = WEBSITE_URL + "/get-student-appointment-details";
export const SUBMIT_STUDENT_APPOINTMENT_URL = WEBSITE_URL + "/submit-student-appointment";
export const GET_ALL_APPOINTMENTS_URL = WEBSITE_URL + "/get-all-appointments";

// Attendance
export const GET_STUDENT_ATTENDANCE_URL = WEBSITE_URL + "/get-student-attendance";
export const GET_ATTENDENCE_DATA_URL = WEBSITE_URL + "/get-attendence-data";
export const DOWNLOAD_STUDENT_ATTENDENCE_URL = WEBSITE_URL + "/download-attendence";

// remarks
export const GET_ALL_REMARKS_URL = WEBSITE_URL + "/get-all-remarks";

//get student time table data
export const GET_STUDENT_TIME_TABLE_URL = WEBSITE_URL + '/get-student-time-table-data'

//inventory manangment url
export const GET_STORE_DATA_URL = WEBSITE_URL + "/get-store-data";
export const GET_SINGLE_ITEM_DATA_URL = WEBSITE_URL + "/get-single-item-data";
export const PLACE_ORDER_URL = WEBSITE_URL + "/place-order";

//online class
export const GET_ONLINE_CLASSES_URL = WEBSITE_URL + "/get-online-classes";
export const GET_OCCASIONAL_VIDEO_URL = WEBSITE_URL + "/get-occasional-videos";

//
export const CHANGE_PASSWORD_URL = WEBSITE_URL + "/change-password";

//
export const PARENT_HELP_URL = WEBSITE_URL + "/sent-help";
export const APPLY_STUDENT_LEAVE_REQUEST_URL = WEBSITE_URL + "/apply-student-leave-request";

//get exam schedule
export const GET_EXAM_SCHEDULE_URL = WEBSITE_URL + '/get-exam-schedule'

//events
export const GET_EVENTS_URL = WEBSITE_URL + "/get-events";

//allergies
export const SUBMIT_STUDENT_ALLERGIES_URL = WEBSITE_URL + "/submit-allergies";
export const GET_STUDENT_ALLERGIES_URL = WEBSITE_URL + "/get-allergies";
// feedback
export const SUBMIT_STUDENT_FEEDBACK_URL = WEBSITE_URL + "/submit-feedback";


//medical instructions
export const SUBMIT_MEDICAL_INSTRUCTION_URL = WEBSITE_URL + "/submit-medical-instructions";
export const GET_MEDICAL_INSTRUCTION_URL = WEBSITE_URL + "/get-medical-instructions";

// activity
export const GET_ALL_ACTIVITY_URL = WEBSITE_URL + "/get-student-activity";

//today in class
export const GET_TODAY_IN_CLASS_URL = WEBSITE_URL + "/get-today-in-class";

//school media
export const GET_ALL_MEDIA_URL = WEBSITE_URL + "/get-all-media";
export const GET_MEDIA_WISE_IMAGES_URL = WEBSITE_URL + "/get-media-wise-images";

//CHAT
export const GET_PARENT_CHAT_LIST_URL = WEBSITE_URL + "/get-parent-chat-list";
export const GET_PARENT_SINGLE_CHAT_VIEW_URL = WEBSITE_URL + "/get-single-parent-chat";
export const SEND_PARENT_CHAT_URL = WEBSITE_URL + "/send-parent-chat";

//answers
export const GET_ANSWERS_URL = WEBSITE_URL + "/get-answers";

//images gallery
export const GET_GALLERY_IMAGES_URL = WEBSITE_URL + '/get-gallery-images';

//----------------------------------Parent App End-------------------------//




// ---------------------------------Teacher App Start---------------------//


// teacher sign up
export const TEACHER_SIGNUP_URL = WEBSITE_URL + "/teacher-signup";
export const TEACHER_COMMON_API_URL = WEBSITE_URL + "/teacher-school-data";

//home
export const GET_TEACHER_HOME_DATA_URL = WEBSITE_URL + "/get-teacher-home-data";

//remark
export const GET_TEACHER_REMARKS_URL = WEBSITE_URL + "/get-teacher-remarks";
export const ADD_REMARK_URL = WEBSITE_URL + "/add-teacher-remark";

// LEAVE
export const GET_ALL_LEAVE_APPLICATION_URL = WEBSITE_URL + "/get-all-leave-application";
export const GET_ALL_TEACHER_LEAVE_URL = WEBSITE_URL + "/get-all-teacher-leave";

// response LEAVE
export const RESPONSE_TO_APPLICATION_URL = WEBSITE_URL + "/response-to-application";
export const SUBMIT_LEAVE_APPLICATION_URL = WEBSITE_URL + "/submit-teacher-leave-application";

//appointments
export const GET_ALL_MEETING_APPOINTMENTS_URL = WEBSITE_URL + "/all-meeting-appointments";
export const RESPONSE_TO_MEETING_APPOINTMENTS_URL = WEBSITE_URL + "/submit-meeting-appointments";

// my students
export const GET_ALL_TEACHER_STUDENTS_URL = WEBSITE_URL + "/get-all-teacher-students";
export const GET_ALL_SINGEL_STUDENTS_INFO_URL = WEBSITE_URL + "/get-single-student-info";

//live class
export const GET_TEACHER_LIVE_CLASSES_URL = WEBSITE_URL + "/get-teacher-online-classes";
export const DELETE_TEACHER_LIVE_CLASSES_URL = WEBSITE_URL + "/delete-teacher-online-class";
export const ADD_LIVE_CLASS_URL = WEBSITE_URL + "/add-teacher-online-class";

//gallery
export const GET_GALLERY_DATA_URL = WEBSITE_URL + "/get-gallery-data";

//homework
export const UPLOAD_HOMEWORK_URL = WEBSITE_URL + "/upload-home-work";
export const GET_HOMEWORK_URL = WEBSITE_URL + "/get-home-work";

//Today in class
export const UPLOAD_TODAY_CLASS_URL = WEBSITE_URL + "/upload-today-class";

// online test
export const CREATE_ONLINE_TEST_URL = WEBSITE_URL + "/create-online-test";
export const SUBMIT_ONLINE_TEST_QUESTIONS_URL = WEBSITE_URL + "/submit-online-test-questions";
export const GET_ALL_ONLINE_EXAM_URL = WEBSITE_URL + "/get-all-teacher-online-test";
export const GET_QUESTIONS_EACH_EXAM_URL = WEBSITE_URL + "/get-all-questions-in-exam";
export const GET_ALL_EXAM_RESULT_URL = WEBSITE_URL + "/get-all-exam-results";

//Mark
export const GET_TEACHER_MARK_URL = WEBSITE_URL + "/get-mark-data";

//CHAT
export const GET_TEACHER_CHAT_LIST_URL = WEBSITE_URL + "/get-teacher-chat-list";
export const GET_TEACHER_SINGLE_CHAT_VIEW_URL = WEBSITE_URL + "/get-single-teacher-chat";
export const SEND_TEACHER_CHAT_URL = WEBSITE_URL + "/send-teacher-chat";

//school media
export const GET_TEACHER_SCHOOL_MEDIA_URL = WEBSITE_URL + "/get-teacher-school-media";
export const ADD_SCHOOL_MEDIA_URL = WEBSITE_URL + "/add-teacher-school-media";
export const GET_MEDIA_WISE_GALLERY_URL = WEBSITE_URL + "/get-media-wise-gallery";

//tutorials
export const GET_TEACHER_TUTORIALS_URL = WEBSITE_URL + "/get-teacher-tutorials";
export const ADD_TEACHER_TUTORIAL_URL = WEBSITE_URL + '/add-teacher-tutorial';

//timetable
export const GET_TEACHER_TIMETABLE_URL = WEBSITE_URL + '/get-teacher-timetable';

//profile
export const GET_TEACHER_PROFILE_URL = WEBSITE_URL + '/get-edit-teacher';
// activity
export const UPLOAD_ACTIVITY_URL = WEBSITE_URL + "/upload-activity";
export const GET_ACTIVITY_URL = WEBSITE_URL + "/get-activity";
// feedback
export const GET_TEACHER_FEEDBACK_URL = WEBSITE_URL + "/get-teacher-feedback";


//allergies
export const GET_TEACHER_ALLERGIES_URL = WEBSITE_URL + '/get-student-allergies';

// toilet info
export const SUBMIT_TOILET_INFO_URL = WEBSITE_URL + "/submit-toilet-info";
export const GET_TOILET_INFO_URL = WEBSITE_URL + "/get-toilet-info";

//medical instruction
export const GET_TEACHER_MEDICAL_URL = WEBSITE_URL + '/get-student-medical';
export const GET_SEARCH_MEDICAL_URL = WEBSITE_URL + '/search-medical';

//student attendence
export const GET_TEACHER_ATTENDENCE_DATA_URL = WEBSITE_URL + "/get-teacher-attendence-data";
export const ADD_STUDENT_ATTENDENCE_URL = WEBSITE_URL + "/add-student-attendence";
export const CHANGE_STUDENT_ATTENDENCE_URL = WEBSITE_URL + "/change-student-attendence";

//upload answer
export const UPLOAD_ANSWER_SHEET_URL = WEBSITE_URL + "/upload-teacher-answer-sheet";
export const GET_TEACHER_UPLOADED_ANSWERS_URL = WEBSITE_URL + '/get-teacher-uploaded-answers';

//study materials
export const GET_TEACHER_MATERIALS_URL = WEBSITE_URL + '/get-teacher-materials';
export const ADD_TEACHER_MATERIAL_URL = WEBSITE_URL + "/add-teacher-material"
// ---------------------------------Teacher App end---------------------//
