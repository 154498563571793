import React, { Component } from "react";
import { connect } from "react-redux";
import msg from "../../../assets/Images/msgicon.png";
import { BsFillChatTextFill } from "react-icons/bs";
import { VscChromeClose } from "react-icons/vsc";
import ContentLoader from "react-content-loader";
// import { Loading } from "../../../elements/Loading";
import BackButton from "../../../elements/BackButton";
import moment from "moment";
import { getAllAppointments } from "../../../redux/Parent/appointment/action";
import { Link } from "react-router-dom";
import img from "../../../assets/Images/no-appointments.png";
class Appointment extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.getAllAppointments(
            user?.data?.auth_token,
            student_id?.value
        );
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    render() {
        const { appointments } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect
                                x="15"
                                y="65"
                                rx="10"
                                ry="10"
                                width="205"
                                height="90"
                            />
                            <rect
                                x="15"
                                y="170"
                                rx="10"
                                ry="10"
                                width="205"
                                height="90"
                            />
                            <rect
                                x="15"
                                y="275"
                                rx="10"
                                ry="10"
                                width="205"
                                height="90"
                            />
                            <rect
                                x="15"
                                y="380"
                                rx="10"
                                ry="10"
                                width="205"
                                height="90"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="w-100 pt-2 px-2">
                            <BackButton title={"Appointment"} />
                        </div>
                        {appointments?.appointments?.length > 0 ? (
                            <React.Fragment>
                                <div className="d-flex flex-column align-content-around justify-content-around mt-5 px-3 pb-7">
                                    {appointments?.appointments?.map((data) => (
                                        <div
                                            className={
                                                data?.appointment_status_id == 1
                                                    ? "appointment-card py-3 px-2 b-r-10 mt-4"
                                                    : data?.appointment_status_id ==
                                                      2
                                                    ? " py-3 px-2 b-r-10 mt-4 appointment-card-bg"
                                                    : "appointment-card py-3 px-2 b-r-10 mt-4"
                                            }
                                        >
                                            <div className="d-flex flex-row justify-content-around align-items-center ">
                                                <div className="flex-shrink-1 px-2">
                                                    <BsFillChatTextFill className="msgbtn" />
                                                </div>
                                                <div className="flex-grow-1 ps-2">
                                                    <div className="d-flex flex-column h-100 justify-content-between align-content-between">
                                                        {/* <img src={img1} className="quote-icon " /> */}
                                                        <span className="fw-nomal  fs-5 fw-500">
                                                            Parent meeting
                                                        </span>
                                                        <span className=" fs-6 mt-2 ">
                                                            {
                                                                data?.student
                                                                    ?.user?.name
                                                            }
                                                            (Student) parent
                                                            meeting,{" "}
                                                            {moment(
                                                                data?.appointment_date,
                                                                "YYYYMMDD"
                                                            ).fromNow()}{" "}
                                                            at{" "}
                                                            {
                                                                data?.appointment_time
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Link to="/make-appointment">
                                    <div className="py-2 d-grid gap-2 bg-white px-2 w-100 position-fixed bottom-0 start-50 translate-middle-x">
                                        <button className="appoinment-btn btn">
                                            Make Appointment
                                        </button>
                                    </div>
                                </Link>
                            </React.Fragment>
                        ) : (
                            <div
                                className="d-flex flex-column align-items-center justify-content-center mt-5 appointment-no-data"
                                style={{

                                    height: "50vh",
                                }}
                            >
                                <img
                                    src={img}
                                    alt="online-test"
                                    style={{
                                        width: "100%",
                                    }}
                                ></img>
                                <span className="text-center fs-5 fw-500">
                                    No Appointments Available
                                </span>
                                <Link to="/make-appointment">
                                    <div className="py-2 d-grid gap-2 bg-white px-2 w-100 position-fixed bottom-0 start-50 translate-middle-x">
                                        <button className="appoinment-btn btn">
                                            Make Appointment
                                        </button>
                                    </div>
                                </Link>
                            </div>
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    appointments: state.appointment.allAppointments,
});

export default connect(mapStateToProps, { getAllAppointments })(Appointment);
