import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Root from './Root';
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";

//parent app routes
// import ParentGetStarted from './components/Teacher/TeacherGetStarted/index';


import ParentLogin from './components/Parent/ParentLogin';
import AllOnlineTest from './components/Parent/AllOnlineTest';
import ParentProfile from './components/Parent/ParentProfile/index';
import Teachers from './components/Parent/ViewTeachers';
import SingleTeacher from './components/Parent/ViewTeachers/SingleTeacher';
import StudentHomework from './components/Parent/StudentHomework';
import UploadHomework from './components/Parent/SingleHomework/UploadHomeWork';
import SingleSubjectNotes from './components/Parent/SingleSubjectNotes';
import Appointment from "./components/Parent/Appointment";
import Review from "./components/Parent/Review";
import Splash from "./components/Parent/Splash";
import TodayClass from "./components/Parent/TodayClass";
import AllSubjects from "./components/Parent/AllSubjects";
import SingleSubject from "./components/Parent/SingleSubject";
import SingleLiveClass from "./components/Parent/SingleLiveClass";
import Events from "./components/Parent/Events";
import TutorialLibrary from "./components/Parent/TutorialLibrary";
import SingleHomework from './components/Parent/SingleHomework';
import AllStudentDetails from "./components/Parent/AllStudentDetails";
import StudentInfo from './components/Parent/StudentInfo';
import ParentEdit from './components/Parent/ParentProfile/ParentEdit';
import Activity from "./components/Parent/Activity";
import PostOnWall from "./components/Parent/PostOnWall";
import Address from "./components/Parent/Address";
import Store from "./components/Parent/Store";
import MyCart from "./components/Parent/MyCart";
import MakeAppointment from "./components/Parent/MakeAppointment";
import Attendence from './components/Parent/Attendence';
import AttendenceList from './components/Parent/Attendence/AttendenceList';
import Fees from './components/Parent/Fees';
import ViewFees from './components/Parent/Fees/ViewFees';
import ViewMarks from './components/Parent/ViewMarks';
import SingleStore from './components/Parent/SingleStore';
import Chat from './components/Parent/Chat';
import SingleChat from './components/Parent/SingleChat';
import ExamSchedule from './components/Parent/ExamSchedule';
import TimeTable from './components/Parent/TimeTable';
import Settings from './components/Parent/Settings';
import Home from './components/Parent/Home';
import Password from './components/Parent/Settings/ChangePassword';
import Mark from './components/Parent/ViewMarks/Mark';
import Help from './components/Parent/Settings/Help';
import SingleTest from './components/Parent/AllOnlineTest/SingleTest';
import TestAnswer from './components/Parent/AllOnlineTest/TestAnswer';
import TestScore from './components/Parent/AllOnlineTest/TestScore';
import Tutorials from './components/Parent/Tutorials';
import OnlineClasses from './components/Parent/OnlineClasses';
import AboutUs from './components/Parent/Settings/';
import LeaveApplication from './components/Parent/LeaveApplication';
import OccasionalVideos from './components/Parent/OccasionalVideos';



//teacher app routes
import TeacherSplash from './components/Teacher/TeacherSplash';
import TeacherLogin from './components/Teacher/TeacherLogin';
import Welcome from './components/Teacher/Welcome';
import TeacherTutorials from './components/Teacher/TeacherTutorials';
import TeacherHomeWork from './components/Teacher/TeacherHomeWork';
import TeacherHome from './components/Teacher/Home';
import LiveClasses from './components/Teacher/LiveCLasses';
import CreateMeeting from './components/Teacher/LiveCLasses/CreateMeeting';
import MyStudent from './components/Teacher/MyStudent';
import LeaveApplications from './components/Teacher/LeaveApplication';
import TeacherAttendence from './components/Teacher/Attendence';
import ViewTeacherAttendence from './components/Teacher/Attendence/ViewAttendence'
import TeacherProfile from './components/Teacher/Profile';
import EditProfile from './components/Teacher/Profile/EditProfile';
import TeacherTimetable from './components/Teacher/TeacherTimetable';
import CreateTutorial from './components/Teacher/TeacherTutorials/CreateTutorial';
import TeacherOnlineTest from './components/Teacher/TeacherAllOnlineTest/TeacherOnlineTest';
import CreateSingleTest from './components/Teacher/TeacherAllOnlineTest/TeacherOnlineTest/CreateSingleTest';
import TeacherAllOnlineTest from './components/Teacher/TeacherAllOnlineTest';
import OnlineTestStudentAnswer from './components/Teacher/TeacherAllOnlineTest/OnlineTestStudentAnswer';
import AddMark from './components/Teacher/AddMark';
import UploadAnswer from './components/Teacher/UploadAnswer';
import TeacherAppointments from './components/Teacher/TeacherAppointments';

import AddAttendence from './components/Teacher/AddAttendence';
import SelectClass from './components/Teacher/AddAttendence/SelectClass';
import MarkHoliday from './components/Teacher/AddAttendence/MarkHoliday';
import Gallery from './components/Teacher/Gallery';
import MyReviews from './components/Teacher/MyReviews';
import AddReview from './components/Teacher/MyReviews/AddReview';
import ViewTeacherMarks from './components/Teacher/ViewTeacherMarks';
import DownloadMark from './components/Teacher/ViewTeacherMarks/DownloadMark';

import SingleStudent from './components/Teacher/MyStudent/SingleStudent';
import EachStudentAttendence from './components/Teacher/AddAttendence/MarkAttendence/EachStudentAttendence/';
import MarkAttendence from './components/Teacher/AddAttendence/MarkAttendence';
import ApplyForLeave from './components/Teacher/ApplyForLeave';
import LeaveHistory from './components/Teacher/ApplyForLeave/LeaveHistory';
import TeacherPostOnWall from './components/Teacher/TeacherPostOnWall';
import MediaImages from './components/Parent/PostOnWall/MediaImages';
import TeacherChat from './components/Teacher/TeacherChat';
import DownloadGradeCard from './components/Parent/DownloadGradeCard';
import GradeCard from './components/Parent/DownloadGradeCard/GradeCard';
import SingleTeacherChat from './components/Teacher/SingleTeacherChat';
import MediaGallery from './components/Teacher/TeacherPostOnWall/MediaGallery';
import TeacherActivity from './components/Teacher/TeacherActivity';
import TeacherTodayClass from './components/Teacher/TeacherTodayClass';
import Allergies from './components/Parent/Allergies';
import StudentAllergies from './components/Teacher/StudentAllergies';
import StudentMedicalInstruction from './components/Teacher/StudentMedicalInstruction';
import MedicalInstruction from './components/Parent/MedicalInstruction';
import ToiletingInfo from './components/Teacher/ToiletingInfo';
import UploadedFiles from './components/Teacher/UploadedFiles';
import Answers from './components/Parent/Answers';
import HomeWork from './components/Teacher/TeacherHomeWork/HomeWork';
import Materials from './components/Teacher/Materials';
import AddMaterial from './components/Teacher/Materials/AddMaterial';
import Images from './components/Parent/Images';
import Feedback from './components/Parent/Feedback';
import TeacherFeedback from './components/Teacher/TeacherFeedback';

const ScrollToTop = () => {
    window.scrollTo(0, 0);
    return null;
};
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Root>
        <BrowserRouter>
            <Route component={ScrollToTop} />
            <Switch>

                {/* parent start */}
                {/* <Route exact path="/" component={withRouter(ParentGetStarted)} /> */}
                <Route exact path="/" component={withRouter(Splash)} />
                <Route exact path="/login" component={withRouter(ParentLogin)} />
                <Route exact path="/home" component={withRouter(Home)} />
                <Route exact path="/parent-profile" component={withRouter(ParentProfile)} />
                <Route exact path="/parent-edit" component={withRouter(ParentEdit)} />
                <Route exact path="/view-teachers" component={withRouter(Teachers)} />
                <Route exact path="/single-teacher/:id" component={withRouter(SingleTeacher)} />
                <Route exact path="/student-details" component={withRouter(AllStudentDetails)} />
                <Route exact path="/student-info" component={withRouter(StudentInfo)} />
                <Route exact path="/address" component={withRouter(Address)} />
                <Route exact path="/subjects" component={withRouter(AllSubjects)} />
                <Route exact path="/time-table" component={withRouter(TimeTable)} />
                <Route exact path="/today-class" component={withRouter(TodayClass)} />
                <Route exact path="/single-live-class" component={withRouter(SingleLiveClass)} />
                <Route exact path="/single-subject/:id/:type" component={withRouter(SingleSubject)} />
                <Route exact path="/single-subject-notes" component={withRouter(SingleSubjectNotes)} />
                <Route exact path="/tutorial-library" component={withRouter(TutorialLibrary)} />
                <Route exact path="/tutorials" component={withRouter(Tutorials)} />
                <Route exact path="/online-classes" component={withRouter(OnlineClasses)} />
                <Route exact path="/homeworks" component={withRouter(StudentHomework)} />
                <Route exact path="/upload-homework" component={withRouter(UploadHomework)} />
                <Route exact path="/single-homework/:id" component={withRouter(SingleHomework)} />
                <Route exact path="/exam-schedule" component={withRouter(ExamSchedule)} />
                <Route exact path="/all-online-tests" component={withRouter(AllOnlineTest)} />
                <Route exact path="/single-test/:id" component={withRouter(SingleTest)} />
                <Route exact path="/test-answer/:id" component={withRouter(TestAnswer)} />
                <Route exact path="/test-score" component={withRouter(TestScore)} />
                <Route exact path="/mark" component={withRouter(Mark)} />
                <Route exact path="/view-mark" component={withRouter(ViewMarks)} />
                <Route exact path="/download-grade-card" component={withRouter(DownloadGradeCard)} />
                <Route exact path="/grade-card" component={withRouter(GradeCard)} />
                <Route exact path="/appointments" component={withRouter(Appointment)} />
                <Route exact path="/make-appointment" component={withRouter(MakeAppointment)} />
                <Route exact path="/attendence" component={withRouter(Attendence)} />
                <Route exact path="/attendence-list" component={withRouter(AttendenceList)} />
                <Route exact path="/fees" component={withRouter(Fees)} />
                <Route exact path="/view-fees" component={withRouter(ViewFees)} />
                <Route exact path="/post-on-wall" component={withRouter(PostOnWall)} />
                <Route exact path="/activity" component={withRouter(Activity)} />
                <Route exact path="/events" component={withRouter(Events)} />
                <Route exact path="/my-chat" component={withRouter(Chat)} />
                <Route exact path="/single-chat/:parent_id/:teacher_id" component={withRouter(SingleChat)} />
                <Route exact path="/review" component={withRouter(Review)} />
                <Route exact path="/store" component={withRouter(Store)} />
                <Route exact path="/single-item/:type/:id" component={withRouter(SingleStore)} />
                <Route exact path="/my-cart" component={withRouter(MyCart)} />
                <Route exact path="/settings" component={withRouter(Settings)} />
                <Route exact path="/change-password" component={withRouter(Password)} />
                <Route exact path="/about-us" component={withRouter(AboutUs)} />
                <Route exact path="/help" component={withRouter(Help)} />
                <Route exact path="/student-leave-application" component={withRouter(LeaveApplication)} />
                <Route exact path="/occasional-videos" component={withRouter(OccasionalVideos)} />
                <Route exact path="/media-images/:id" component={withRouter(MediaImages)} />
                <Route exact path="/allergies" component={withRouter(Allergies)} />
                <Route exact path="/medical-instructions" component={withRouter(MedicalInstruction)} />
                <Route exact path="/feedback" component={withRouter(Feedback)} />
                <Route exact path="/answers" component={withRouter(Answers)} />
                <Route exact path="/gallery-images" component={withRouter(Images)} />

                {/* parent ends */}

                {/* Teacher Starts */}
                <Route exact path="/splash" component={withRouter(TeacherSplash)} />
                <Route exact path="/teacher-login" component={withRouter(TeacherLogin)} />
                <Route exact path="/welcome" component={withRouter(Welcome)} />
                <Route exact path="/teacher-tutorials" component={withRouter(TeacherTutorials)} />
                <Route exact path="/teacher-homework" component={withRouter(TeacherHomeWork)} />
                <Route exact path="/teacher-home" component={withRouter(TeacherHome)} />
                <Route exact path="/live-classes" component={withRouter(LiveClasses)} />
                <Route exact path="/create-meeting" component={withRouter(CreateMeeting)} />
                <Route exact path="/leave-applications" component={withRouter(LeaveApplications)} />
                <Route exact path="/my-students" component={withRouter(MyStudent)} />
                <Route exact path="/list-appointments" component={withRouter(TeacherAppointments)} />
                <Route exact path="/teacher-attendence" component={withRouter(TeacherAttendence)} />
                <Route exact path="/view-teacher-attendence" component={withRouter(ViewTeacherAttendence)} />
                <Route exact path="/teacher-profile" component={withRouter(TeacherProfile)} />
                <Route exact path="/teacher-profile-edit" component={withRouter(EditProfile)} />
                <Route exact path="/teacher-time-table" component={withRouter(TeacherTimetable)} />
                <Route exact path="/create-tutorial" component={withRouter(CreateTutorial)} />
                <Route exact path="/create-online-test" component={withRouter(TeacherOnlineTest)} />
                <Route exact path="/teacher-single-test/:id" component={withRouter(CreateSingleTest)} />
                <Route exact path="/view-all-online-tests" component={withRouter(TeacherAllOnlineTest)} />
                <Route exact path="/student-test-answer/:id" component={withRouter(OnlineTestStudentAnswer)} />
                <Route exact path="/add-mark" component={withRouter(AddMark)} />
                <Route exact path="/upload-answer" component={withRouter(UploadAnswer)} />
                <Route exact path="/teacher-feedback" component={withRouter(TeacherFeedback)} />
                <Route exact path="/add-attendence-home" component={withRouter(AddAttendence)} />
                <Route exact path="/select-class/:type" component={withRouter(SelectClass)} />
                <Route exact path="/mark-holiday" component={withRouter(MarkHoliday)} />
                <Route exact path="/gallery" component={withRouter(Gallery)} />
                <Route exact path="/my-reviews" component={withRouter(MyReviews)} />
                <Route exact path="/add-review" component={withRouter(AddReview)} />
                <Route exact path="/view-teacher-marks" component={withRouter(ViewTeacherMarks)} />
                <Route exact path="/download-teacher-mark" component={withRouter(DownloadMark)} />
                <Route exact path="/view-single-student/:id" component={withRouter(SingleStudent)} />
                <Route exact path="/show-all-attendence" component={withRouter(EachStudentAttendence)} />
                <Route exact path="/mark-attendence" component={withRouter(MarkAttendence)} />
                <Route exact path="/apply-for-leave" component={withRouter(ApplyForLeave)} />
                <Route exact path="/show-leave-history" component={withRouter(LeaveHistory)} />
                <Route exact path="/teacher-post-on-wall" component={withRouter(TeacherPostOnWall)} />
                <Route exact path="/teacher-chatlist" component={withRouter(TeacherChat)} />
                <Route exact path="/single-chat-teacher/:parent_id/:teacher_id" component={withRouter(SingleTeacherChat)} />
                <Route exact path="/media-gallery/:id" component={withRouter(MediaGallery)} />
                <Route exact path="/teacher-activity" component={withRouter(TeacherActivity)} />
                <Route exact path="/teacher-today-class" component={withRouter(TeacherTodayClass)} />
                <Route exact path="/get-student-allergies" component={withRouter(StudentAllergies)} />
                <Route exact path="/get-student-medical-instructions" component={withRouter(StudentMedicalInstruction)} />
                <Route exact path="/student-toilet-info" component={withRouter(ToiletingInfo)} />
                <Route exact path="/uploaded-files" component={withRouter(UploadedFiles)} />
                <Route exact path="/get-homework" component={withRouter(HomeWork)} />
                <Route exact path="/teacher-materials" component={withRouter(Materials)} />
                <Route exact path="/add-material" component={withRouter(AddMaterial)} />
                {/* Teacher ends */}
            </Switch>
        </BrowserRouter>
    </Root>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
