import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton'
import { Link } from 'react-router-dom'
import { getTeacherRemarks } from '../../../redux/Teacher/student/action'
import moment from "moment";
import img from '../../../assets/Images/noreview.png'
import ContentLoader from 'react-content-loader'
export class MyReviews extends Component {
    state = {
        loading: false,
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { teacher } = this.props
        this.props.getTeacherRemarks(teacher.data.auth_token)
        this.setState({
            loading: false,
        })
    }
    render() {
        const { remarks } = this.props
        console.log(remarks)
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="-2 0 280 580"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#ecebeb"}
                        >
                            <rect x="3" y="30" rx="10" ry="10" width="270" height="140" />
                            <rect x="3" y="180" rx="10" ry="10" width="270" height="140" />
                            <rect x="3" y="330" rx="10" ry="10" width="270" height="140" />


                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {remarks?.success ?
                            <>
                                <BackButton title={"My Reviews"} />
                                <div className='px-3 mt-4x'>
                                    {remarks?.data?.length > 0 ?
                                        <>
                                            {remarks?.data?.length > 0 && remarks?.data?.map((data) => (
                                                <div className="mt-3 review-container px-2 pb-4 pt-2" style={{ fontSize: '12px', fontWeight: '500' }}>
                                                    <div className='review-head d-flex justify-content-between px-3 py-2 text-white'>
                                                        <div className='d-flex flex-column'>
                                                            <span>{data?.student?.user?.name} </span>
                                                            <span>Class {data?.student?.student_class?.name} Section {data?.student?.section?.name}</span>
                                                        </div>
                                                        <div className='pt-3'><span>Date:{moment(data?.created_at).format("DD/MM/YYYY")}</span></div>
                                                    </div>
                                                    <div className='text-muted px-1 pb-1 pt-2' style={{ fontSize: '13px' }}><span>{data?.remark}</span></div>
                                                </div>
                                            ))}
                                        </>
                                        :
                                        <div className='mt-5 pt-5 w-100 d-flex flex-column justify-content-center align-items-center'>
                                            <img src={img} alt="no reviews" style={{ width: '65vw' }} className='' />
                                            <span>No Remarks Found...</span>
                                        </div>
                                    }

                                </div>
                                <Link to="/add-review">
                                    <div className='position-fixed bottom-0 py-2 w-100 px-3'>
                                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                                            Add Review
                                        </button>
                                    </div>
                                </Link>
                            </>
                            : null}
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    teacher: state.teacher.teacher,
    remarks: state.student.remarks
})

export default connect(mapStateToProps, { getTeacherRemarks })(MyReviews)
