import React, { Component } from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Modal from "react-modal";
import { CgCheckO } from 'react-icons/cg';
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton';
import { sentFeedback } from '../../../redux/Parent/user/action';
import { BsFillChatTextFill } from 'react-icons/bs';
import { getStudentAppointmentDetails } from "../../../redux/Parent/appointment/action";
import Select from "react-select";

export class Feedback extends Component {

    state = {
        open: false,
        feedback: "",
        schoolFeedback: "",
        selectedTeacher: "",
        teacherId: null,
    }
    componentDidMount() {
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        // for getting staffs only nothing else
        this.props.getStudentAppointmentDetails(
            user.data.auth_token,
            student_id.value
        );
    }
    handleInput = (e) => {
        this.setState({ feedback: e });
        console.log(this.state.feedback);
    }
    handleSchoolInput = (e) => {
        this.setState({ schoolFeedback: e.target.value });


    }
    handleTeacher = (selectedTeacher) => {
        this.setState({ selectedTeacher, teacherId: selectedTeacher.value });
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));

        let formdata = new FormData();
        formdata.append("student_id", student_id.value);
        formdata.append('token',user.data.auth_token);
        formdata.append("teacher_id", this.state.teacherId);
        formdata.append("feedback", this.state.feedback);
        formdata.append("type", "TEACHER");

        this.props.sentFeedback(formdata).then((response) => {

            if (response.data.success) {
                this.modalOpen();
                e.target.reset();
            } else {
                console.log("Something went wrong");
            }
        });

    }

    onSchoolFormSubmit = (e) =>{
        e.preventDefault();
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        let formdata = new FormData();
        formdata.append("student_id", student_id.value);
        formdata.append('token',user.data.auth_token);

        formdata.append("school_feedback", this.state.schoolFeedback);
        formdata.append("type", "SCHOOL");
        this.props.sentFeedback(formdata).then((response) => {

            if (response.data.success) {
                this.modalOpen();
                e.target.reset();
            } else {
                console.log("Something went wrong");
            }
        });
    }


    modalOpen = () => {
        this.setState({ open: true });
    };

    modalClose = () => {
        this.setState({ open: false });
    };
    render() {

        let student_id = JSON.parse(localStorage.getItem("student_id"));

        const { makeAppointment } = this.props;

        const teachers = makeAppointment?.staffs?.map((sub) => ({
            value: sub.id, label: sub.user?.name
        }))
        return (
            <React.Fragment>
                <BackButton title={"Feedback"} />
                <Form onSubmit={this.onSubmit}>
                    <div className='px-3 mt-4'>

                        <div className="mt-3">
                            <div className=" flex-column p-1">
                                <div className="d-flex justify-content-between mt-3 homework-input">
                                    <div className="text-muted"> Student </div>
                                    <div style={{ fontWeight: "600" }}>
                                        {student_id?.label}
                                    </div>
                                </div>
                            </div>
                            <div className=" flex-column p-1">
                                <div className="  pt-2 ">
                                    <Select
                                        className="text-muted"
                                        styles={customStyles}
                                        placeholder="Select Teacher "
                                        options={teachers}
                                        value={this.state.selectedTeacher}
                                        onChange={this.handleTeacher}
                                    />
                                </div>
                            </div>

                            <InputGroup hasValidation className="mt-2 " style={{ boxSizing: 'border-box' }} >
                                <FormControl

                                    type="text"
                                    placeholder="Teacher Feedback"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value);
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div className=' py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                            Submit
                        </button>
                    </div>
                </Form>

                <Form onSubmit={this.onSchoolFormSubmit}>
                    <div className='px-3 mt-4'>

                        <div className="mt-3">
                            <div className=" flex-column p-1">
                                <div className="d-flex justify-content-between mt-3 homework-input">
                                    <div className="text-muted"> Student </div>
                                    <div style={{ fontWeight: "600" }}>
                                        {student_id?.label}
                                    </div>
                                </div>
                            </div>

                            <div className=" flex-column p-1">
                                <div className="  pt-2 ">
                                    <Form.Group className=" ">
                                        <textarea
                                            className="appointment-textarea pt-2 px-2"
                                            rows={3}
                                            placeholder="School Feedback"
                                            required
                                            onChange={(e) => {
                                                this.handleSchoolInput(e)
                                            }}
                                        ></textarea>
                                    </Form.Group>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=' py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                            Submit
                        </button>
                    </div>
                </Form>


                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={() => this.modalClose()}
                    style={modalStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "70vw",
                            width: '70vw',
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-center align-items-between">
                                <div className='d-flex justify-content-center mt-5'
                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                    <CgCheckO color={"#7ECB40"} size={"4em"} />
                                </div>
                                <div className='d-flex justify-content-center mt-4'
                                    style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                    Feedback Added Successfully</div>
                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    makeAppointment: state.appointment.makeAppointment

})



export default connect(mapStateToProps, { sentFeedback, getStudentAppointmentDetails })(Feedback)

const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
        border: "none", // tried border: 'none'
        boxShadow: "none", // tried border: 'none'
        outline: "none",
    }),
    control: (provided) => ({
        ...provided,
        height: "45px",
        border: "none",
        boarderRadius: "10px",
        fontSize: "13px",
        borderStyle: "none",
        backgroundColor: "rgba(190, 175, 179, 0.05)",
        outline: "none",
        boxShadow: "0px 0px 12px rgba(0, 0, 0, 0.02) inset",
        shadowOpacity: 0.5,
        shadowRadius: 50,
    }),
};
