
import { GET_ALL_LEAVE_APPLICATION, GET_ALL_TEACHER_LEAVE } from "./actionType";

const initialState = {
    studentLeave: [],
    teacherLeave: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_LEAVE_APPLICATION:
            return { ...state, studentLeave: action.payload };
        case GET_ALL_TEACHER_LEAVE:
            return { ...state, teacherLeave: action.payload };
        default:
            return state;
    }
}
