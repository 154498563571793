import { GET_All_ONLINE_TEST, GET_All_TEST_SCORE, GET_SINGLE_ONLINE_TEST, SHOW_SINGLE_ONLINE_TEST_ANSWER } from "./actionType";

import {
    GET_All_ONLINE_TEST_URL,
    GET_ALL_TEST_SCORE_URL,
    GET_SINGLE_ONLINE_TEST_URL,
    SHOW_SINGLE_ONLINE_TEST_ANSWER_URL,
    SUBMIT_ALL_ANSWER_URL,
} from "../../../api";

import Axios from "axios";

export const getAllOnlineTest = (token,student_id) => (dispatch) => {
    return Axios.post(GET_All_ONLINE_TEST_URL, { token,student_id }).then((response) => {
        let test = response.data;
        return dispatch({
            type: GET_All_ONLINE_TEST,
            payload: test,
        });
    });
};

export const getSingleOnlineTest = (token, id) => (dispatch) => {
    return Axios.post(GET_SINGLE_ONLINE_TEST_URL, { token, id }).then(
        (response) => {
            let test = response.data;
            return dispatch({
                type: GET_SINGLE_ONLINE_TEST,
                payload: test,
            });
        }
    );
};

export const submitAllAnswer =
    (token, student_id, exam_id, formdata) => (dispatch) => {
        return Axios.post(SUBMIT_ALL_ANSWER_URL, {
            token,
            student_id,
            exam_id,
            formdata,
        });
    };


    export const getAllTestScore = (token, id) => (dispatch) => {
        return Axios.post(GET_ALL_TEST_SCORE_URL, { token, id }).then(
            (response) => {
                let test = response.data;
                return dispatch({
                    type: GET_All_TEST_SCORE,
                    payload: test,
                });
            }
        );
    };


    export const showTestAnswer = (token, id,exam_id) => (dispatch) => {
        return Axios.post(SHOW_SINGLE_ONLINE_TEST_ANSWER_URL, { token, id ,exam_id}).then(
            (response) => {
                let test = response.data;
                return dispatch({
                    type: SHOW_SINGLE_ONLINE_TEST_ANSWER,
                    payload: test,
                });
            }
        );
    };
