import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { CgProfile } from 'react-icons/cg';
import { BiLibrary } from 'react-icons/bi';
// import { FcGraduationCap } from 'react-icons/fc';
import { BsColumnsGap, BsFillPersonFill, BsHddStack, BsNewspaper } from 'react-icons/bs';
import { MdEventNote, MdMapsHomeWork, MdOutlineSchool } from 'react-icons/md';
// import { RiFileList2Line } from 'react-icons/ri';
import { AiFillPlayCircle } from 'react-icons/ai';
import { IoNewspaper } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import { VscPreview } from 'react-icons/vsc';
import { FaBriefcaseMedical, FaHistory, FaPumpMedical, FaUserGraduate } from 'react-icons/fa';


export class Catagories extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="px-4 py-3">
                    <div style={{ fontSize: '18px', fontWeight: '500' }}>Categories</div>
                </div>
                <div className="px-3 liveclass gap-2 hidden-scroll-bar" style={{ gridTemplateColumns: "repeat(4,1fr)", overflowX: "scroll", }}>
                    <Link to="/teacher-tutorials">
                        <div className='d-flex flex-column align-items-center '>
                            <div className='position-relative catagorie-div ' style={{ backgroundColor: '#9694FF' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <BiLibrary style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name  text-muted text-center py-1' >Tutorial Library</div>
                        </div>
                    </Link>
                    <Link to="/live-classes">
                        <div className='d-flex flex-column align-items-center ' >
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#57CAEB' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <AiFillPlayCircle style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Live Classes</div>
                        </div>
                    </Link>
                    <Link to="/teacher-attendence">
                        <div className='d-flex flex-column align-items-center ' >
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#fbb176' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <BsFillPersonFill style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Attendence</div>
                        </div>
                    </Link>
                    <Link to="/teacher-homework">
                        <div className='d-flex flex-column align-items-center ' >
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#018F9C' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <MdMapsHomeWork style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Home work</div>
                        </div>
                    </Link>
                </div>
                <div className="px-3 liveclass gap-2 hidden-scroll-bar mt-2" style={{ gridTemplateColumns: "repeat(4,1fr)", overflowX: "scroll", }}>
                    <Link to="/view-all-online-tests">
                        <div className='d-flex flex-column align-items-center ' >

                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#f9db83' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <IoNewspaper style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Online test</div>
                        </div>
                    </Link>
                    <Link to="/get-student-allergies">
                        <div className='d-flex flex-column align-items-center ' >

                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#730a40' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <FaPumpMedical style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Student Allergies</div>
                        </div>
                    </Link>
                    <Link to="view-teacher-marks">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#245bb3' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <VscPreview style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >Marks</div>
                        </div>
                    </Link>
                    <Link to="my-students">
                        <div className='d-flex flex-column align-items-center'>
                            <div className='position-relative catagorie-div' style={{ backgroundColor: '#9bcc67' }}>
                                <div className='position-absolutes text-center' style={{ marginTop: '13px' }}>
                                    <FaUserGraduate style={{ fontSize: '1.5rem', color: 'white' }} />
                                </div>
                            </div>
                            <div className='cata-name text-center text-muted py-1' >My Students</div>
                        </div>
                    </Link>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(Catagories)
