import React, { Component } from "react";
import { connect } from "react-redux";
import { CgMenuLeft } from "react-icons/cg";
import teach from "../../../assets/Images/teach.png";
// import Catagories from "./catagories";
// import LastViewedTutorial from "./LastViewedTutorial";
import Footer from "../Footer";
import { Catagories } from "./Categories";
import LiveClass from "./LiveClass";

import { MyAppointments } from "./MyAppoinments";
import HomeViewTutorial from "./HomeViewTutorial";
// import component
import Drawer from "react-modern-drawer";
//import styles
import "react-modern-drawer/dist/index.css";
import { Link } from "react-router-dom";
import { BsCameraFill, BsFillCalendar2WeekFill, BsFillPlayCircleFill, BsImages, } from "react-icons/bs";
import { FaBriefcaseMedical, FaTelegramPlane, FaToiletPaper, FaUserAltSlash } from "react-icons/fa";
import { HiOutlineLogout, HiOutlineUpload } from "react-icons/hi";
import { FcLeave } from "react-icons/fc";
import { MdRateReview } from "react-icons/md";
import { getTeacherHomeData } from "../../../redux/Teacher/home/action"
import ContentLoader from "react-content-loader";
import { GiTeacher } from "react-icons/gi";
import { GoListOrdered } from "react-icons/go";
import Ink from "react-ink";
import Modal from "react-modal";
import { logoutUser } from "../../../redux/Teacher/user/action";
export class Home extends Component {
    state = {
        loading: false,
        isOpen: false,
        liveclasses: null,
        appointments: null,
        tutorials: null,
        open_logout: false,
    };
    toggleDrawer = () => {
        this.setState({ isOpen: !this.state.isOpen });
    };
    handleAccountLogout = (e) => {
        e.preventDefault();
        this.setState({ open_logout: !this.state.open_logout, isOpen: false });
    };
    __handleLogout = (e) => {
        e.preventDefault();

        this.props.logoutUser();
        localStorage.clear();
        window.location.replace("/teacher-login");
        this.props.history.replace("/teacher-login");
    };
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        let teacher = JSON.parse(localStorage.getItem("teacher"))
        if (!teacher) {
            localStorage.setItem("teacher", JSON.stringify({ staff_id: user?.data?.staff?.id, label: user?.data?.name }));
        }
        this.props.getTeacherHomeData(user?.data?.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        liveclasses: response.payload.liveclasses,
                        appointments: response.payload.appointments,
                        tutorials: response.payload.tutorials,
                    })
                }
            })
    }
    render() {
        const { user } = this.props;
        const { liveclasses, appointments, tutorials } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            viewBox="0 0 236 470"
                            backgroundColor="#F3F3F3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="15" y="26" rx="6" ry="9" width="24" height="24" />
                            <rect x="100" y="26" rx="6" ry="9" width="60" height="26" />
                            <rect x="15" y="65" rx="10" ry="10" width="205" height="82" />
                            <rect x="15" y="160" rx="8" ry="15" width="100" height="20" />
                            {/* category 1st row */}
                            <rect x="15" y="190" rx="6" ry="9" width="40" height="39" />
                            <rect x="18" y="235" width="35" height="6" rx="3" />
                            <rect x="70" y="190" rx="6" ry="9" width="40" height="39" />
                            <rect x="73" y="235" width="35" height="6" rx="3" />
                            <rect x="127" y="190" rx="6" ry="9" width="40" height="39" />
                            <rect x="130" y="235" width="35" height="6" rx="3" />
                            <rect x="180" y="190" rx="6" ry="9" width="40" height="39" />
                            <rect x="183" y="235" width="35" height="6" rx="3" />
                            {/* category 2nd row */}
                            <rect x="15" y="270" rx="6" ry="9" width="40" height="39" />
                            <rect x="18" y="315" width="35" height="6" rx="3" />
                            <rect x="70" y="270" rx="6" ry="9" width="40" height="39" />
                            <rect x="73" y="315" width="35" height="6" rx="3" />
                            <rect x="127" y="270" rx="6" ry="9" width="40" height="39" />
                            <rect x="130" y="315" width="35" height="6" rx="3" />
                            <rect x="180" y="270" rx="6" ry="9" width="40" height="39" />
                            <rect x="183" y="315" width="35" height="6" rx="3" />
                            {/* live class */}
                            <rect x="15" y="350" rx="8" ry="15" width="100" height="20" />
                            <rect x="15" y="380" rx="10" ry="10" width="91" height="100" />
                            <rect x="113" y="380" rx="10" ry="10" width="91" height="100" />
                            <rect x="210" y="380" rx="6" ry="10" width="20" height="100" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div style={{ paddingBottom: "30vw" }}>
                            <div className="px-3 py-4">
                                <div className="pt-2 ">
                                    <div className="d-flex flex-row align-items-center justify-content-between position-relative">
                                        <div
                                            className="p-1 fw-bold"
                                            style={{ borderRadius: "100px" }}
                                            onClick={this.toggleDrawer}
                                        >
                                            <span className="fw-bold">
                                                <CgMenuLeft size="1.7em" />
                                            </span>
                                        </div>
                                        <div className="d-flex justify-content-center fw-bolder text-center">
                                            <span
                                                className=""
                                                style={{
                                                    fontSize: "22px",
                                                    color: "373E43",
                                                }}
                                            >
                                                Home
                                            </span>
                                        </div>
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                            {console.log(user)}

                            {user && user.data &&
                                <div className="px-4">
                                    <div className="teacher-card" style={{ background: "linear-gradient(180deg, #C04670 0%, #CF6150 100%)" }}>
                                        <div className="flex-grow-1 ps-4">
                                            <h2 style={{ margin: "6px 6px 0px 6px", fontSize: "14px", fontWeight: "700", color: "#FFF" }}>
                                                Welcome {user?.data?.name}
                                            </h2>
                                            <span style={{ marginLeft: "6px", fontSize: "10px", fontWeight: "400", color: "#FFF" }}>
                                                Today there are 0 notifications
                                            </span>
                                        </div>
                                        <div className="teacher-sub-image">
                                            <img src={teach} alt="social science" />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div>
                                <Catagories />
                                <LiveClass live_class={liveclasses} />
                                <MyAppointments appointments={appointments} />
                                <HomeViewTutorial tutorials={tutorials} />
                            </div>
                            <Footer current="home" />
                        </div>
                        <Drawer
                            open={this.state.isOpen}
                            onClose={this.toggleDrawer}
                            direction="left"
                            className="bla bla bla pb-4"
                            style={{
                                background:
                                    "linear-gradient(178.74deg, #BF4474 1.08%, #D9733A 156.57%)",
                                color: "#fff",
                                fontSize: "16px",
                                fontWeight: "500",
                                lineHeight: "19px",
                                letterSpacing: "0.03em",
                                overflowY: 'scroll',
                            }}
                        >
                            <div
                                className="nav "
                                style={{
                                    paddingTop: "20vw",
                                }}
                            >

                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <BsFillPlayCircleFill size={25} />
                                    </div>
                                    <Link
                                        to="/teacher-tutorials"
                                        className="text-white"
                                    >
                                        View Tutorials
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <HiOutlineUpload size={25} />
                                    </div>
                                    <Link
                                        to="/teacher-materials"
                                        className="text-white"
                                    >
                                        Upload Study Material
                                    </Link>
                                </div>



                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <FaUserAltSlash size={25} />
                                    </div>
                                    <Link
                                        to="/leave-applications"
                                        className="text-white"
                                    >
                                        Student Leave Application
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <BsImages size={25} />
                                    </div>
                                    <Link to="/gallery" className="text-white">
                                        Gallery
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <BsCameraFill size={25} />
                                    </div>
                                    <Link to="/teacher-post-on-wall" className="text-white">
                                        School Media
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <BsFillCalendar2WeekFill size={25} />
                                    </div>
                                    <Link
                                        to="/list-appointments"
                                        className="text-white"
                                    >
                                        My Appointments
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <GiTeacher size={25} />
                                    </div>
                                    <Link
                                        to="/teacher-today-class"
                                        className="text-white"
                                    >
                                        Today In Class
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <FaBriefcaseMedical size={25} />
                                    </div>
                                    <Link
                                        to="/get-student-medical-instructions"
                                        className="text-white"
                                    >
                                        Medical Instructions
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <FaToiletPaper size={25} />
                                    </div>
                                    <Link
                                        to="/student-toilet-info"
                                        className="text-white"
                                    >
                                        Toileting Info
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <MdRateReview size={25} />
                                    </div>
                                    <Link to="/my-reviews" className="text-white">
                                        Review
                                    </Link>
                                </div>

                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <FcLeave size={25} />
                                    </div>
                                    <Link
                                        to="/apply-for-leave"
                                        className="text-white"
                                    >
                                        Apply For Leave
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <GoListOrdered size={25} />
                                    </div>
                                    <Link
                                        to="/teacher-activity"
                                        className="text-white"
                                    >
                                        Activity Logger
                                    </Link>
                                </div>
                                <div className="nav-tab d-flex align-items-center ms-3 mt-2">
                                    <div className="me-3 nav-tab-icon">
                                        <FaTelegramPlane size={25} />
                                    </div>
                                    <Link
                                        to="/teacher-feedback"
                                        className="text-white"
                                    >
                                        View Feedback
                                    </Link>
                                </div>
                                <div
                                    className="nav-tab d-flex align-items-center ms-3 mt-2"
                                    onClick={(e) => this.handleAccountLogout(e)}
                                >
                                    <div className="me-3 nav-tab-icon">
                                        <HiOutlineLogout size={25} />
                                    </div>
                                    <div className="text-white">Logout</div>
                                </div>
                            </div>
                        </Drawer>
                        <Modal
                    isOpen={this.state.open_logout}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={() => this.handlePopup()}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="d-flex flex-column align-items-center"
                        style={{ backgroundColor: "#fff", padding: "13px" }}
                    >
                        <div className="text-center">
                            <span className="text-danger fs-4 fw-semibold">
                                Logout?
                            </span>
                        </div>
                        <div className="text-muted ms-4 mt-3">
                            <span>Are you sure you about logging out ?</span>
                        </div>

                        <div className="d-flex justify-content-around w-100 gap-4 px-2 mt-3">
                            <div className="text-center mr-4 w-100">
                                <button
                                    className="delete-button-meals p-2 px-2 w-100 position-relative"
                                    onClick={(e) => this.handleAccountLogout(e)}
                                    style={{
                                        borderColor: "#000",
                                        color: "#000",
                                        border: "1px solid #000",
                                        width: "100%",
                                    }}
                                >
                                    {" "}
                                    NO
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>

                            <div className="text-center w-100">
                                <button
                                    className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                    onClick={(e) => this.__handleLogout(e)}
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#ce0060",
                                        borderColor: "#ce0060",
                                    }}
                                >
                                    YES
                                    <Ink
                                        duration={500}
                                        style={{ color: "#000" }}
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
});
export default connect(mapStateToProps, {
    getTeacherHomeData,logoutUser
})(Home);

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #BF4474",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
