import Select from "react-select";
import React, { Component } from "react";
import { connect } from "react-redux";
import BackButton from "../../../../elements/BackButton";
import { getTeacherSchoolData } from "../../../../redux/Teacher/user/action";
import {createOnlineTest} from "../../../../redux/Teacher/onlineTest/action"
import { Form } from "react-bootstrap";

export class TeacherOnlineTest extends Component {
    state = {
        subject: [],
        section: [],
        online_test: {},
    };

    componentDidMount() {
        const { user } = this.props;
        this.props.getTeacherSchoolData(user.data.auth_token);
    }

    onHandleInput = (value, name) => {
        const test = this.state.online_test;
        test[name] = value;
        this.setState({ online_test: test });
    };

    getSubjectSections = (value) => {
        const { classes } = this.props;
        const sections = classes?.data?.student_classes
            ?.find((data) => data.id == value)
            ?.sections?.map((section) => ({
                value: section.id,
                label: section.name,
            }));
        this.setState({ section: sections });
        const subjects = classes?.data?.student_classes
            ?.find((data) => data.id == value)
            ?.subjects?.map((subject) => ({
                value: subject.id,
                label: subject.name,
            }));

        this.setState({ subject: subjects });
    };

    onSubmit = (e) => {
        e.preventDefault();
        const {user} = this.props;
        const {online_test} = this.state;
        let formData = new FormData();

        formData.append('token', user.data.auth_token);
        formData.append('class_id',online_test.class_id);
        // formData.append('section_id',online_test.section_id);
        formData.append('subject_id',online_test.subject_id);
        formData.append('term_id',online_test.term_id);
        formData.append('date',online_test.date);
        formData.append('to',online_test.to_time);
        formData.append('from',online_test.from_time);
        formData.append('name',online_test.name);

        this.props.createOnlineTest(formData).then((response) => {
            if(response.data.success){
                window.location.replace("/view-all-online-tests");
            }else{
                console.log("Something went Wrong!");
            }
        })
    }

    render() {
        const { classes } = this.props;
        const { subject, section } = this.state;
        const options = classes?.data?.student_classes?.map((data) => ({
            value: data.id,
            label: data.name,
        }));
        const terms = classes?.data?.terms?.map((data) => ({
            value: data.id,
            label: data.name,
        }));

        return (
            <React.Fragment>
                <BackButton title={"Online Test"} />
                <Form onSubmit={this.onSubmit}>
                    <div className="px-3">
                        <div className="mt-3">
                            <Select
                                className="text-muted required attendence-input px-2"
                                placeholder="Class"
                                styles={customStyles}
                                options={options}
                                onChange={(e) => {
                                    let value = e.value;

                                    this.onHandleInput(value, "class_id");
                                    this.getSubjectSections(value);
                                }}
                            />
                        </div>

                        {/* <div className="mt-3">
                            <Select
                                className="text-muted required attendence-input px-2"
                                placeholder="Section"
                                styles={customStyles}
                                options={section}
                                onChange={(e) => {
                                    let value = e.value;
                                    this.onHandleInput(value, "section_id");
                                }}
                            />
                        </div> */}

                        <div className="mt-3">
                            <Select
                                className="text-muted required attendence-input px-2"
                                placeholder="Subject"
                                styles={customStyles}
                                options={subject}
                                onChange={(e) => {
                                    let value = e.value;
                                    this.onHandleInput(value, "subject_id");
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <Select
                                className="text-muted attendence-input px-2"
                                placeholder="Term"
                                styles={customStyles}
                                options={terms}
                                onChange={(e) => {
                                    let value = e.value;
                                    this.onHandleInput(value, "term_id");
                                }}
                            />
                        </div>
                        <div className="mt-3">
                        <label className="text-muted px-3">Date</label>

                            <input
                                type="date"
                                className="text-muted attendence-input px-3"
                                placeholder="Date"
                                required
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.onHandleInput(value, "date");
                                }}
                            />
                        </div>

                        <div className="mt-3">
                        <label className="text-muted px-3">From Time</label>
                            <input
                                type="time"
                                className="text-muted attendence-input px-3"
                                placeholder="Exam From Time"
                                required
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.onHandleInput(value, "from_time");
                                }}
                            />
                        </div>
                        <div className="mt-3">
                        <label className="text-muted px-3">To Time</label>
                            <input
                                type="time"
                                className="text-muted attendence-input px-3"
                                placeholder="Exam To Time"
                                required
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.onHandleInput(value, "to_time");
                                }}
                            />
                        </div>
                        <div className="mt-3">
                            <input
                                type="text"
                                className="text-muted attendence-input px-3"
                                style={{ fontSize: "13px" }}
                                placeholder="Test Name"
                                required
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.onHandleInput(value, "name");
                                }}
                            />
                        </div>
                    </div>
                    <div className="position-fixed bottom-0 py-2 w-100 px-3">
                        <button type="submit" className="btn attendence-dwld-btn mt-2 py-2 w-100">
                            Create Online Test
                        </button>
                    </div>
                </Form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    classes: state.teacher.schooldata,
});

export default connect(mapStateToProps, { getTeacherSchoolData ,createOnlineTest})(
    TeacherOnlineTest
);

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: "#fff",
        border: "none", // tried border: 'none'
        boxShadow: "none", // tried border: 'none'
        outline: "none",
    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: "13px",
        borderStyle: "none",
        background: "rgba(190, 175, 179, 0.05)",
        outline: "none",
        fontWeight: "400",
        lineHeight: "14px",
        letterSpacing: "1px",
        color: "#86878E",
    }),
};
