import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "react-bootstrap/Form";
import welcome from "../../../assets/Images/techer-welcome.png";
import student from "../../../assets/Images/student-bg.png";
import background from "../../../assets/Images/loginbg.png";
import { Link, Redirect } from "react-router-dom";

class Welcome extends Component {
    state = {

    };

    componentDidMount() {
        setTimeout(() =>{
            window.location.replace("/splash");

        },3000)
    }
    render() {

        return (
            <React.Fragment>
                <div
                    className="d-flex flex-column "
                    style={{

                        height: "100vh",
                        background: "linear-gradient(176.3deg, #FFEEFF 14.54%, #F5E7FF 224.07%)",
                        overflow: "hidden",
                        width: "100%",
                    }}
                >
                    <div className="welcome-logo d-flex justify-content-center align-items-center">
                        <img src={welcome} alt="logo" className="w-100"/>
                    </div>

                    <div className="student-apple-img d-flex justify-content-center align-items-center">
                        <img src={student} alt="logo" className="w-100"/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}



export default Welcome;
