import React, { Component } from 'react'
import { connect } from 'react-redux'
import BackButton from '../../../elements/BackButton';
import { applyLeaveRequest } from '../../../redux/Parent/user/action'
import Form from 'react-bootstrap/Form';
import moment from 'moment';
import { BottomSheet } from "react-spring-bottom-sheet";
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import Select from 'react-select';
import { Link } from 'react-router-dom';

export class LeaveApplication extends Component {
    state = {
        loading: false,
        types: [],
        start_date: '',
        end_date: '',
        open: false,
        reason: ''
    };


    __submit = (e) => {
        e.preventDefault();
        this.setState({ loading: true, });
        const { start_date, end_date } = this.state;
        let start = start_date ? start_date : moment().format("YYYY-MM-DD");
        let end = end_date ? end_date : moment().format("YYYY-MM-DD");
        const { user } = this.props;
        let student_id = JSON.parse(localStorage.getItem("student_id"));
        this.props.applyLeaveRequest(user.data.auth_token, student_id.value, start, end, this.state.reason).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, open: true });
            }
        })
    }
    notTomorrow = () => {
        let date;
        date = moment().format("YYYY-MM-DD");
        return date;
    }

    _closeSheet = () => {
        this.setState({ open: false })
    }
    // filterValue = (values) => {
    //     let filterValue = []
    //     values.forEach((item) => {
    //         filterValue.push({ value: item.id, label: item.type })
    //     })
    //     return filterValue
    // }


    render() {
        const { student } = this.props;
        console.log(student.student.student_class,9090);
        const { start_date, end_date, types } = this.state;
        return (
            <React.Fragment>
                <BackButton title={"Leave Application"} />
                <Form onSubmit={(e) => this.__submit(e)}>
                    <div className='px-3 mt-4' style={{ paddingBottom: '24vw' }}>
                        <div className='d-flex justify-content-between mt-2 homework-input'>
                            <div className='text-muted'> Class  </div>
                            <div style={{ fontWeight: '600' }}> {student?.student?.student_class?.name}  </div>
                        </div>

                        <div className='d-flex justify-content-between mt-2 homework-input'>
                            <div className='text-muted'> Section  </div>
                            <div style={{ fontWeight: '600' }}>  {student?.student?.section?.name} </div>
                        </div>

                        <div className='d-flex justify-content-between mt-2 homework-input'>
                            <div className='text-muted'> Student  </div>
                            <div style={{ fontWeight: '600' }}>  {student?.student?.user?.name}  </div>
                        </div>

                        <div className='d-flex justify-content-between mt-2 homework-input'>
                            <div className='text-muted'> Roll No.  </div>
                            <div style={{ fontWeight: '600' }}>  {student?.student?.roll_number} </div>
                        </div>
                        {/* <div className="flex-column mt-2">
                            <Select className="text-muted attendence-input ps-2" placeholder='Select Leave Type' styles={customStyles} options={this.filterValue(types)}
                                onChange={e => this.setState({ selected_feetype: e, not_selected: false })} />
                        </div> */}

                        <div className="mt-2">
                            <textarea className="form-control z-depth-1 textarea-box-meeting px-3 py-2"
                                id="exampleFormControlTextarea6"
                                rows="2"
                                required
                                placeholder="Reason"
                                onChange={(e) => {
                                    let value = e.target.value;
                                    this.setState({ reason: value })
                                }}>
                            </textarea>
                        </div>
                        <Form.Group className="mt-1">
                            <Form.Label className="text-muted">Leave From</Form.Label>
                            <Form.Control type='date' className='input-homework w-100'
                                placeholder='Start Date' required
                                value={start_date ? start_date : this.notTomorrow()}
                                min={this.notTomorrow()}
                                onChange={(e) => {
                                    let start_date = e.target.value ? e.target.value : this.notTomorrow();
                                    this.setState({ start_date: start_date, noData: false });
                                }} />
                        </Form.Group>

                        <Form.Group className="mt-1" >
                            <Form.Label className="text-muted">Leave To</Form.Label>
                            <Form.Control type='date' className='input-homework w-100'
                                placeholder='Start Date' required
                                value={end_date ? end_date : this.notTomorrow()}
                                min={start_date ? start_date : this.notTomorrow()}

                                onChange={(e) => {
                                    let end_date = e.target.value ? e.target.value : this.notTomorrow();
                                    this.setState({ end_date: end_date, noData: false });
                                }} />
                        </Form.Group>
                    </div>
                    <div className='position-fixed bottom-0 pb-2 w-100 px-3' style={{ backgroundColor: '#fff' }}>
                        <button type='submit' className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                            Submit
                        </button>
                    </div>
                </Form>
                <BottomSheet
                    open={this.state.open}
                    onDismiss={() => this._closeSheet()} >
                    <div className="px-3" style={{ paddingBottom: '10vw' }}>
                        <div className="px-3 d-flex flex-column align-items-center text-center">
                            <div className="w-100 mt-3 fs-2 text-bold">
                                <IoCheckmarkCircleOutline style={{ fontSize: '2.5em', color: '#3CC19E' }} />
                            </div>
                            <div className="mt-3 fs-6 fw-bolder" style={{ color: '#3CC19E' }}>
                                Leave application  submitted
                            </div>
                            <Link to={'/home'}>
                                <button className="btn attendence-dwld-btn mt-3 py-3 px-4">
                                    OK
                                </button>
                            </Link>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.parent,
    student: state.user.student
})

export default connect(mapStateToProps, {
    applyLeaveRequest
})(LeaveApplication)

// const customStyles = {
//     option: (provided, state) => ({
//         ...provided,
//         backgroundColor: '#fff',
//         border: 'none', // tried border: 'none'
//         boxShadow: 'none', // tried border: 'none'
//         outline: 'none',

//     }),
//     control: (provided) => ({
//         ...provided,
//         height: "50px",
//         width: "88vw",
//         border: "none",
//         boarderRadius: "10px",
//         fontSize: '13px',
//         borderStyle: 'none',
//         background: 'rgba(190, 175, 179, 0.05)',
//         outline: 'none',
//         fontWeight: '400',
//         lineHeight: '14px',
//         letterSpacing: '1px',
//         color: '#86878E',
//         // boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1) inset",
//         // shadowOpacity: 0.5,
//         // shadowRadius: 50,

//     })
// }
