import Axios from "axios";
import { CREATE_ONLINE_TEST_URL, GET_ALL_EXAM_RESULT_URL, GET_ALL_ONLINE_EXAM_URL, GET_QUESTIONS_EACH_EXAM_URL, SUBMIT_ONLINE_TEST_QUESTIONS_URL }
from "../../../api";


import { GET_ALL_EXAM_RESULT, GET_ALL_ONLINE_EXAM, GET_QUESTIONS_EACH_EXAM } from "./actionType"


export const createOnlineTest = (fromData) => (dispatch) => {
    return Axios.post(CREATE_ONLINE_TEST_URL, fromData);
};


// get all onlin test
export const getAllOnlineExam = (token) => (dispatch) => {
    return Axios.post(GET_ALL_ONLINE_EXAM_URL, { token }).then(
        (response) => {
            const exam = response.data;
            return dispatch({
                type: GET_ALL_ONLINE_EXAM,
                payload: exam,
            });
        }
    );
};


export const submitTestQuestions = (fromData) => (dispatch) => {
    return Axios.post(SUBMIT_ONLINE_TEST_QUESTIONS_URL, fromData);
};


export const getAllQuestionsEachExam = (token,exam_id) => (dispatch) => {
    return Axios.post(GET_QUESTIONS_EACH_EXAM_URL, { token ,exam_id}).then(
        (response) => {
            const exam = response.data;
            return dispatch({
                type: GET_QUESTIONS_EACH_EXAM,
                payload: exam,
            });
        }
    );
};


export const getAllExamResult = (token,exam_id) => (dispatch) => {
    return Axios.post(GET_ALL_EXAM_RESULT_URL, { token ,exam_id}).then(
        (response) => {
            const exam = response.data;
            return dispatch({
                type: GET_ALL_EXAM_RESULT,
                payload: exam,
            });
        }
    );
};
