export const GET_PARENT_PROFILE = "GET_PARENT_PROFILE";
export const GET_EDIT_PARENT = "GET_EDIT_PARENT";
export const PARENT_SIGNUP = "PARENT_SIGNUP";
// export const GET_STUDENT_DETAILS = "GET_STUDENT_DETAILS";
export const GET_STUDENT = "GET_STUDENT";
export const GET_ALL_REMARKS = "GET_ALL_REMARKS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGOUT_USER = "LOGOUT_USER";
export const PARENT_HELP = "PARENT_HELP";
export const GET_ALLERGIES = "GET_ALLERGIES";
export const GET_MEDICAL_INSTRUCTION = "GET_MEDICAL_INSTRUCTION";
export const APPLY_STUDENT_LEAVE_REQUEST = "APPLY_STUDENT_LEAVE_REQUEST";
