import React, { Component } from 'react'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { CgCheckO } from 'react-icons/cg';
import { connect } from 'react-redux'
import Select from 'react-select'
import BackButton from '../../../elements/BackButton';
import Modal from "react-modal";
import { getTeacherSchoolData } from '../../../redux/Teacher/user/action';
import { getToiletInfo, submitToilletInfo } from '../../../redux/Teacher/student/action';
import img from '../../../assets/Images/noreview.png'
import moment from 'moment';
export class ToiletingInfo extends Component {
    state = {
        info: {},
        sections: [],
        std_class: [],
        students: [],
        open: false,
    }

    componentDidMount() {
        const { user } = this.props;
        this.props.getTeacherSchoolData(user.data.auth_token);
        this.props.getToiletInfo(user.data.auth_token);
    }
    modalOpen = () => {
        this.setState({ open: true });
    };
    modalClose = () => {
        this.setState({ open: false });
    };
    handleInput = (value, type) => {
        const input = this.state.info;
        input[type] = value;
        this.setState({ info: input });
    }
    getSections = (val) => {

        const { schooldata } = this.props;
        const cls = schooldata?.data?.student_classes?.find((cls) => (cls.id === val))
        const sec = cls?.sections?.map((section) => ({
            value: section.id,
            label: section.name
        }))
        this.setState({ sections: sec, std_class: cls })
    }

    getStudents = (val) => {
        const students = this.state.std_class?.students?.filter((student) => student.section_id === val)?.map((student) => ({
            value: student.id,
            label: student?.user?.name
        }))
        this.setState({ students: students })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { info } = this.state;
        const { user } = this.props;
        let formData = new FormData();
        formData.append('class_id', info.class_id);
        formData.append('section_id', info.section_id);
        formData.append('student_id', info.student_id);
        formData.append('details', info.details);
        formData.append('token', user.data.auth_token);

        this.props.submitToilletInfo(formData).then((response) => {
            console.log(response);
            if (response.data.success) {
                this.modalOpen();
                e.target.reset();
                this.props.getToiletInfo(user.data.auth_token);
            }
        })


    }

    render() {
        const { schooldata, info } = this.props;
        const { sections, students } = this.state;
        const classes = schooldata?.data?.student_classes?.map((cls) => ({
            value: cls.id,
            label: cls.name,
        }))

        return (
            <React.Fragment>
                <BackButton title={"Toileting Info"} />
                <Form onSubmit={this.onSubmit} id="remarkform">
                    <div className='px-3 mt-4'>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Class' styles={customStyles}
                                options={classes}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "class_id");
                                    this.getSections(value);
                                }} />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='Section' styles={customStyles}
                                options={sections}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "section_id");
                                    this.getStudents(value);
                                }} />
                        </div>
                        <div className="mt-3">
                            <Select className="text-muted attendence-input px-2" placeholder='student' styles={customStyles}
                                options={students}
                                onChange={e => {
                                    let value = e.value;
                                    this.handleInput(value, "student_id");
                                }} />
                        </div>
                        <div className="mt-3">
                            <InputGroup hasValidation className="mt-2 " style={{ boxSizing: 'border-box' }} >
                                <FormControl
                                    type="text"
                                    placeholder="Comment"
                                    className="attendence-input"
                                    onChange={e => {
                                        let value = e.target.value;
                                        this.handleInput(value, "details");
                                    }}
                                    required
                                />
                            </InputGroup>
                        </div>
                    </div>
                    <div className=' py-2 w-100 px-3'>
                        <button className='btn attendence-dwld-btn mt-2 py-3 w-100' >
                            Submit
                        </button>
                    </div>
                </Form>
                <React.Fragment>
                    <div className='px-3 mt-4x pb-4'>

                        <>
                            {info?.data?.length > 0 && info?.data?.map((data) => (
                                <div className="mt-3 review-container px-2 pb-3 pt-2" style={{ fontSize: '12px', fontWeight: '500' }}>
                                    <div className='review-head d-flex justify-content-between px-3 py-2 text-white'>
                                        <div className='d-flex flex-column'>
                                            <span>{data?.student?.user?.name} </span>
                                            <span>Class {data?.class?.name} Section {data?.section?.name}</span>
                                        </div>
                                        <div>
                                            <span>{moment(data?.created_at).format('DD-MM-YYYY')}</span>
                                        </div>
                                    </div>
                                    <div className='text-muted px-1 pb-1 pt-2' style={{ fontSize: '13px' }}><span>{data?.details}</span></div>
                                </div>
                            ))}
                        </>



                    </div>
                </React.Fragment>
                <Modal
                    isOpen={this.state.open}
                    ariaHideApp={false}
                    onRequestClose={() => this.modalClose()}
                    style={modalStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{
                            borderRadius: "5px",
                            height: "70vw",
                            width: '70vw',
                            zIndex: "9999",
                        }}
                    >
                        <React.Fragment>
                            <div className="d-flex flex-column justify-content-center align-items-between">
                                <div className='d-flex justify-content-center mt-5'
                                    style={{ fontSize: '18px', fontWeight: '600', color: '#2B2C2F' }}>
                                    <CgCheckO color={"#7ECB40"} size={"4em"} />
                                </div>
                                <div className='d-flex text-center justify-content-center align-items-center mt-4'
                                    style={{ fontSize: '15px', fontWeight: '400', color: '#2B2C2F' }}>
                                    Toilet Info Added Successfully</div>
                                <div className='d-flex justify-content-between w-100 position-fixed' style={{ bottom: '20%', left: '0%' }}>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.teacher.teacher,
    schooldata: state.teacher.schooldata,
    info: state.student.info
})


export default connect(mapStateToProps, { getTeacherSchoolData, getToiletInfo, submitToilletInfo })(ToiletingInfo)
const customStyles = {
    option: (provided, state) => ({
        ...provided,
        backgroundColor: '#fff',
        border: 'none', // tried border: 'none'
        boxShadow: 'none', // tried border: 'none'
        outline: 'none',

    }),
    control: (provided) => ({
        ...provided,
        height: "50px",
        width: "88vw",
        border: "none",
        boarderRadius: "10px",
        fontSize: '13px',
        borderStyle: 'none',
        background: 'rgba(190, 175, 179, 0.05)',
        outline: 'none',
        fontWeight: '400',
        lineHeight: '14px',
        letterSpacing: '1px',
        color: '#86878E',
    })

}
const modalStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #C24A6B",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};
